const date = new Date()

export const lastTwoDigitsYear = date
  .getFullYear()
  .toString()
  .slice(-2)

export const penultimateTwoDigitsYear = date
  .getFullYear()
  .toString()
  .slice(2, 3)
