import React, { useContext, useState, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import {
  setLocalStorage,
  clearLocalStorage,
  getLocalStorage,
} from '../../services/storage'

import { RESPONSE_CODE } from '../../constants/responseCode'
import {
  labels,
  CODE_RECURRENCE,
  CODE_CALLED_ME,
  CODE_BNPL,
  CODE_WALLET_RECOVERY,
  CODE_CBK_USSD,
  CODE_SEND_A_RECHARGE,
  CODE_DEMOBI,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'

import handleRedirectHome from '../../components/HandleRedirectHome'
import PaymentActionsNext from '../../components/PaymentActionsNext'
import CustomButton from '../../components/CustomButton'
import Billing from '../../components/Billing'

import logoBi from '../../assets/img/Bi-Logotipo-Azul.png'
import tiendasApps from '../../assets/img/tiendasApps.svg'
import logoBiButton from '../../assets/img/Bi-Logotipo-Blanco.png'
import processingPaymentImage from '../../assets/img/processingPayment.svg'
import successfulPayment from '../../assets/img/successfulPayment.svg'
import happy from '../../assets/img/happy.svg'
import sad from '../../assets/img/sad.svg'

const renderPackageInfo = packageData => {
  return (
    <div className="claro__payment__process__success__informations__bundle">
      <span className="claro__payment__process__success__informations__bundle__textBoldBlack">
        Paquete {''}
      </span>
      <span className="claro__payment__process__success__informations__bundle__textBoldRed">
        {`${packageData.subtitle}`}
      </span>
      <span className=" claro__payment__process__success__informations__bundle__textNormalBlack">
        {` Vigencia: ${packageData.validity}`} por {''}
      </span>
      <span className="claro__payment__process__success__informations__bundle__textBoldBlack">
        {packageData.currency}
        {packageData.amount}
      </span>
    </div>
  )
}

const ClaroPayPaymentProcess = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    OK_CLARO_PAY,
    SESSION_CODE_EXPIRED,
    ANTIFRAUD_LOCK,
    TRANSACTION_DECLINED,
    VALIDATION_CODE_EXPIRED,
    VALIDATION_EXPIRATION_DATE_ERROR,
    FAIL_SERVER_CLARO_PAY,
    VALIDATION_NO_LONGER_HAVE_DEBT,
    DATOS_INCORRECTOS,
    PAYMENT_SERVICE_ERROR,
    INSUFFICIENT_FUNDS_BI,
    ACCOUNT_INACTIVE_BI,
    WITHOUT_PROVISIONING_BI,
    CONNECTION_FAILURE_BI,
    ERROR_LIMIT_BI,
    PAYMENT_TIMEOUT_BI,
  } = RESPONSE_CODE

  const {
    PROCESSING_PAYMENT,
    PURCHASE_MADE,
    PAID_PACKAGE,
    REGRESAR_BI,
  } = labels

  const {
    recurrenceData,
    responseService,
    processingPayment,
    callMeProduct,
    bnplData,
    callMeData,
    walletRecoveryData,
    walletRecoveryProduct,
    minecoBecasData,
    minecoBecasPackSelected,
    cbkUssdData,
    cbkUssdProduct,
    iIsVisibleAddNewAccountBi,
    selectAccountBi,
    requestId,
    urlAccountBi,
    urltiendaApps,
    homeData,
    homeProduct,
    sendMetrics,
    getImage,
    sendARechargeData,
    sendARechargeProduct,
    demoBiData,
  } = claroPayContext

  const [canClickButtonRegresarBi, setCanClickButtonRegresarBi] = useState(true)

  const isAnyDataPresent = () => {
    return (
      walletRecoveryData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  useEffect(() => {
    setLocalStorage(PROCESO_PAGO, true)

    if (isAnyDataPresent()) {
      let metricsData
      if (selectAccountBi !== null || iIsVisibleAddNewAccountBi) {
        metricsData = {
          metricType: 'Proceso_BAN',
          metricName: 'Proceso_BAN',
        }
      } else {
        metricsData = {
          metricType: 'Proceso_Pago',
          metricName: 'Proceso_Pago',
        }
      }
      sendMetrics(metricsData)
    }

    if (!processingPayment) {
      const url = getLocalStorage(PAYMENT_PROCESS)
      if (url === null || url === undefined) {
        handleRedirectHome()
      } else {
        window.location.href = window.location.origin + url
      }
    }
  }, [])

  const metricsProcessOk = async () => {
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'PagoExitoso',
        metricName: 'PagoExitoso_',
      }
      await sendMetrics(metricsData)
    }
  }

  const metricsProcessErrorButton = async () => {
    if (isAnyDataPresent()) {
      const metricsDataDefault = {
        metricType: 'Error_ExisteProblema_BotónReintentar',
        metricName: 'Error_ExisteProblema_BotónReintentar',
      }
      switch (responseService.code) {
        case SESSION_CODE_EXPIRED.code:
          const metricsData_SESSION_CODE_EXPIRED = {
            metricType: 'Error_General-BotónRegresarCompra',
            metricName: 'Error_General-BotónRegresarCompra',
          }
          await sendMetrics(metricsData_SESSION_CODE_EXPIRED)
          break

        case ANTIFRAUD_LOCK.code:
          const metricsData_ANTIFRAUD_LOCK = {
            metricType: 'Error_VariosIntentos_BotónRegresarCompra',
            metricName: 'Error_VariosIntentos_BotónRegresarCompra',
          }
          await sendMetrics(metricsData_ANTIFRAUD_LOCK)
          break

        case TRANSACTION_DECLINED.code:
          const metricsData_TRANSACTION_DECLINED = {
            metricType: 'Error_TCRechazada_BotónRegresarCompra',
            metricName: 'Error_TCRechazada_BotónRegresarCompra',
          }
          await sendMetrics(metricsData_TRANSACTION_DECLINED)
          break

        case VALIDATION_CODE_EXPIRED.code:
          await sendMetrics(metricsDataDefault)
          break

        case VALIDATION_EXPIRATION_DATE_ERROR.code:
          const metricsData_VALIDATION_EXPIRATION_DATE_ERROR = {
            metricType: 'Error_TCFechaInvalida-BotónIngresarFecha',
            metricName: 'Error_TCFechaInvalida-BotónIngresarFecha',
          }
          await sendMetrics(metricsData_VALIDATION_EXPIRATION_DATE_ERROR)
          break

        case FAIL_SERVER_CLARO_PAY.code:
          await sendMetrics(metricsDataDefault)
          break

        case VALIDATION_NO_LONGER_HAVE_DEBT.code:
          const metricsData_VALIDATION_NO_LONGER_HAVE_DEBT = {
            metricType: 'Error_DeudaYaPagada-BotónRegresarCompra',
            metricName: 'Error_DeudaYaPagada-BotónRegresarCompra',
          }
          await sendMetrics(metricsData_VALIDATION_NO_LONGER_HAVE_DEBT)
          break

        case DATOS_INCORRECTOS.code:
          const metricsData_DATOS_INCORRECTOS = {
            metricType: 'Error_TCRechazada_BotónRegresarCompra',
            metricName: 'Error_TCRechazada_BotónRegresarCompra',
          }
          await sendMetrics(metricsData_DATOS_INCORRECTOS)
          break

        case PAYMENT_SERVICE_ERROR.code:
          const metricsData_PAYMENT_SERVICE_ERROR = {
            metricType: 'Error_TCRechazada_BotónRegresarCompra',
            metricName: 'Error_TCRechazada_BotónRegresarCompra',
          }
          await sendMetrics(metricsData_PAYMENT_SERVICE_ERROR)
          break

        default:
          await sendMetrics(metricsDataDefault)
          break
      }
    }
  }

  useEffect(() => {
    if (processingPayment && responseService !== null) {
      clearLocalStorage(PROCESO_PAGO)
      clearLocalStorage(METRICS_SESSION)
      if (isAnyDataPresent()) {
        const metricsDataDefault = {
          metricType: 'Error_ExisteProblema',
          metricName: 'Error_ExisteProblema',
        }
        const metricsDataBi = {
          metricType: 'Error_BAN',
          metricName: 'Error_BAN',
        }

        switch (responseService.code) {
          case OK_CLARO_PAY.code:
            metricsProcessOk()
            break

          case SESSION_CODE_EXPIRED.code:
            const metricsData_SESSION_CODE_EXPIRED = {
              metricType: 'Error_General-TimeOut',
              metricName: 'Error_General-TimeOut',
            }
            sendMetrics(metricsData_SESSION_CODE_EXPIRED)
            break

          case ANTIFRAUD_LOCK.code:
            const metricsData_ANTIFRAUD_LOCK = {
              metricType: 'Error_VariosIntentos',
              metricName: 'Error_VariosIntentos',
            }
            sendMetrics(metricsData_ANTIFRAUD_LOCK)
            break

          case TRANSACTION_DECLINED.code:
            const metricsData_TRANSACTION_DECLINED = {
              metricType: 'Error_TCRechazada',
              metricName: 'Error_TCRechazada',
            }
            sendMetrics(metricsData_TRANSACTION_DECLINED)
            break

          case VALIDATION_CODE_EXPIRED.code:
            sendMetrics(metricsDataDefault)
            break

          case VALIDATION_EXPIRATION_DATE_ERROR.code:
            const metricsData_VALIDATION_EXPIRATION_DATE_ERROR = {
              metricType: 'Error_TCFechaInvalida',
              metricName: 'Error_TCFechaInvalida',
            }
            sendMetrics(metricsData_VALIDATION_EXPIRATION_DATE_ERROR)
            break

          case FAIL_SERVER_CLARO_PAY.code:
            sendMetrics(metricsDataDefault)
            break

          case VALIDATION_NO_LONGER_HAVE_DEBT.code:
            const metricsData_VALIDATION_NO_LONGER_HAVE_DEBT = {
              metricType: 'Error_DeudaYaPagada',
              metricName: 'Error_DeudaYaPagada',
            }
            sendMetrics(metricsData_VALIDATION_NO_LONGER_HAVE_DEBT)
            break

          case DATOS_INCORRECTOS.code:
            const metricsData_DATOS_INCORRECTOS = {
              metricType: 'Error_TCRechazada',
              metricName: 'Error_TCRechazada',
            }
            sendMetrics(metricsData_DATOS_INCORRECTOS)
            break

          case PAYMENT_SERVICE_ERROR.code:
            const metricsData_PAYMENT_SERVICE_ERROR = {
              metricType: 'Error_TCRechazada',
              metricName: 'Error_TCRechazada',
            }
            sendMetrics(metricsData_PAYMENT_SERVICE_ERROR)
            break

          case ACCOUNT_INACTIVE_BI.code:
            const metricsData_ACCOUNT_INACTIVE_BI = {
              metricType: 'Error_CuentaInactiva-Invalida',
              metricName: 'Error_CuentaInactiva-Invalida',
            }
            sendMetrics(metricsData_ACCOUNT_INACTIVE_BI)
            break

          case INSUFFICIENT_FUNDS_BI.code:
            const metricsData_INSUFFICIENT_FUNDS_BI = {
              metricType: 'Error_CuentaSinFondos',
              metricName: 'Error_CuentaSinFondos',
            }
            sendMetrics(metricsData_INSUFFICIENT_FUNDS_BI)
            break

          case WITHOUT_PROVISIONING_BI.code:
            sendMetrics(metricsDataBi)
            break

          case CONNECTION_FAILURE_BI.code:
            sendMetrics(metricsDataBi)
            break

          case ERROR_LIMIT_BI.code:
            sendMetrics(metricsDataBi)
            break

          case PAYMENT_TIMEOUT_BI.code:
            sendMetrics(metricsDataBi)
            break

          default:
            sendMetrics(metricsDataDefault)
            break
        }
      }
    }
  }, [responseService])

  useEffect(() => {
    if (requestId) {
      clearLocalStorage(PROCESO_PAGO)
    }
  }, [requestId])

  const redirect = () => {
    if (minecoBecasData) {
      const url = localRoutes.routesClaroPay.claroBecasMineco
      window.location.href = window.location.origin + url
    }

    if (walletRecoveryData) {
      const localCodeWR = getLocalStorage(CODE_WALLET_RECOVERY)
      const url = `${localRoutes.routesClaroPay.claroWalletRecovery}?code=${localCodeWR}`
      window.location.href = window.location.origin + url
    }

    if (bnplData) {
      const localCodeBN = getLocalStorage(CODE_BNPL)
      const url = `${localRoutes.routesClaroPay.claroBuyNowPayLater}?code=${localCodeBN}`
      window.location.href = window.location.origin + url
    }

    if (callMeData) {
      const localCodeWCM = getLocalStorage(CODE_CALLED_ME)
      const url = `${localRoutes.routesClaroPay.claroWhoCalledMe}?code=${localCodeWCM}`
      window.location.href = window.location.origin + url
    }

    if (cbkUssdData) {
      const localCodeUSSD = getLocalStorage(CODE_CBK_USSD)
      const url = `${localRoutes.routesClaroPay.claroCallBackUssd}?code=${localCodeUSSD}`
      window.location.href = window.location.origin + url
    }

    if (recurrenceData) {
      const localCodeRE = getLocalStorage(CODE_RECURRENCE)
      const url = `${localRoutes.routesClaroPay.claroRecurrence}?code=${localCodeRE}`
      window.location.href = window.location.origin + url
    }

    if (sendARechargeData) {
      const localCodeSendARecharge = getLocalStorage(CODE_SEND_A_RECHARGE)
      const url = `${localRoutes.routesClaroPay.claroSendARecharge}?code=${localCodeSendARecharge}`
      window.location.href = window.location.origin + url
    }

    if (homeData) {
      handleRedirectHome()
    }

    if (demoBiData) {
      const localCodeDemoBi = getLocalStorage(CODE_DEMOBI)
      const url = `${localRoutes.routesClaroPay.claroDemoBi}?code=${localCodeDemoBi}`
      window.location.href = window.location.origin + url
    }
  }

  const handleRedirect = () => {
    metricsProcessErrorButton()
    redirect()
  }

  const handleRedirectBack = async () => {
    setCanClickButtonRegresarBi(false)
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'IrAPP_Regresar',
        metricName: 'IrAPP_Regresar',
      }
      await sendMetrics(metricsData)
    }
    redirect()
  }

  const [deepLinkVisible, setDeepLinkVisible] = useState(true)

  const handleClickTiendaApps = () => {
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'InstalarAPP',
        metricName: 'InstalarAPP',
      }
      sendMetrics(metricsData)
    }
  }

  const handleClickBi = () => {
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'IrAPP',
        metricName: 'IrAPP',
      }
      sendMetrics(metricsData)
    }
    setDeepLinkVisible(false)
  }

  const packageData =
    minecoBecasPackSelected ||
    recurrenceData?.pack ||
    (cbkUssdProduct?.productType === 'BUNDLE' && cbkUssdProduct) ||
    (callMeProduct?.productType === 'BUNDLE' && callMeProduct) ||
    (sendARechargeProduct?.productType === 'BUNDLE' && sendARechargeProduct) ||
    (homeProduct?.productType === 'BUNDLE' && homeProduct) ||
    demoBiData?.pack

  return (
    processingPayment && (
      <div className="claro__payment__process">
        {responseService !== null ? (
          <React.Fragment>
            {responseService.code === OK_CLARO_PAY.code && (
              <div className="claro__payment__process__sucess">
                <div className="claro__payment__process__sucess__information">
                  <img
                    className="claro__payment__process__sucess__information__image"
                    src={successfulPayment}
                    alt="sucess"
                  />
                  <p className="claro__payment__process__sucess__information__message">
                    <span role="img" aria-label="Snowman">
                      {PURCHASE_MADE}
                    </span>
                  </p>

                  {packageData && (
                    <div className="claro__payment__process__success__informations__package">
                      {renderPackageInfo(packageData)}
                    </div>
                  )}

                  {bnplData?.debt && (
                    <div className="claro__payment__process__success__informations__package">
                      <div className="claro__payment__process__success__informations__package__info">
                        <p>{PAID_PACKAGE}</p>
                        <p>{bnplData.debt.productDescription}</p>
                        <p className="claro__payment__process__success__informations__package__amount">
                          {bnplData.debt.currency}
                          {bnplData.debt.amountOfDebt}
                        </p>
                      </div>
                    </div>
                  )}

                  {walletRecoveryData && walletRecoveryProduct && (
                    <div className="claro__payment__process__success__informations__package">
                      <div className="claro__payment__process__success__informations__package__info">
                        <p>
                          SUPERPACK <span> + Pago de Deuda</span>
                        </p>
                        <p>
                          {`${walletRecoveryData.pack.subtitle}`}
                          <span>{` Vigencia ${walletRecoveryData.pack.validity}`}</span>
                        </p>
                      </div>
                      <p className="claro__payment__process__success__informations__package__amount">{`${
                        walletRecoveryData.pack.currency
                      }${walletRecoveryData.pack.amount +
                        walletRecoveryData.debt.amountPayable}`}</p>
                    </div>
                  )}

                  {walletRecoveryData && !walletRecoveryProduct && (
                    <div className="claro__payment__process__success__informations__package">
                      <div className="claro__payment__process__success__informations__package__info">
                        <p>Pago de Deuda</p>
                        <p>{`${walletRecoveryData.debt.productDescription}`}</p>
                      </div>
                      <p className="claro__payment__process__success__informations__package__amount">{`${walletRecoveryData.debt.currency}${walletRecoveryData.debt.amountPayable}`}</p>
                    </div>
                  )}
                </div>

                <div className="claro__payment__process__success__informations__separator" />

                <Billing />
              </div>
            )}

            {(responseService.code === SESSION_CODE_EXPIRED.code ||
              responseService.code === ANTIFRAUD_LOCK.code ||
              responseService.code === TRANSACTION_DECLINED.code ||
              responseService.code === VALIDATION_CODE_EXPIRED.code ||
              responseService.code === VALIDATION_EXPIRATION_DATE_ERROR.code ||
              responseService.code === FAIL_SERVER_CLARO_PAY.code ||
              responseService.code === VALIDATION_NO_LONGER_HAVE_DEBT.code ||
              responseService.code === DATOS_INCORRECTOS.code ||
              responseService.code === PAYMENT_SERVICE_ERROR.code ||
              responseService.code === INSUFFICIENT_FUNDS_BI.code ||
              responseService.code === ACCOUNT_INACTIVE_BI.code ||
              responseService.code === WITHOUT_PROVISIONING_BI.code ||
              responseService.code === CONNECTION_FAILURE_BI.code ||
              responseService.code === ERROR_LIMIT_BI.code ||
              responseService.code === PAYMENT_TIMEOUT_BI.code) && (
              <React.Fragment>
                <div className="claro__payment__process__error">
                  {responseService.img !== null &&
                    responseService.img !== undefined && (
                      <img
                        className="claro__payment__process__image"
                        src={getImage(responseService.img)}
                        alt="error"
                      />
                    )}
                  {responseService.code !== ANTIFRAUD_LOCK.code &&
                    responseService.code !== VALIDATION_CODE_EXPIRED.code &&
                    responseService.code !== FAIL_SERVER_CLARO_PAY.code &&
                    responseService.code !== INSUFFICIENT_FUNDS_BI.code &&
                    responseService.code !== ACCOUNT_INACTIVE_BI.code &&
                    responseService.code !== TRANSACTION_DECLINED.code &&
                    responseService.code !== WITHOUT_PROVISIONING_BI.code &&
                    responseService.code !== CONNECTION_FAILURE_BI.code &&
                    responseService.code !== ERROR_LIMIT_BI.code &&
                    responseService.code !== PAYMENT_TIMEOUT_BI.code && (
                      <p className="claro__payment__process__generalMessage">
                        {responseService.description
                          .split('.')
                          .map((sentence, index, array) => (
                            <React.Fragment key={sentence.trim()}>
                              {sentence.trim()}
                              {index === array.length - 1 ? null : '.'}
                              <br />
                            </React.Fragment>
                          ))}
                      </p>
                    )}

                  {responseService.code === ANTIFRAUD_LOCK.code && (
                    <p className="claro__payment__process__generalMessage">
                      {responseService.description
                        .split('.')
                        .map((sentence, index, array) => (
                          <React.Fragment key={sentence.trim()}>
                            {sentence.trim()}
                            {index === array.length - 1 ? (
                              <img
                                src={happy}
                                alt="Happy"
                                className="claro__payment__process__error__imageEmoji"
                              />
                            ) : (
                              '.'
                            )}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                  )}

                  {responseService.code === VALIDATION_CODE_EXPIRED.code && (
                    <p className="claro__payment__process__generalMessage">
                      {responseService.description
                        .split('.')
                        .map((sentence, index, array) => (
                          <React.Fragment key={sentence.trim()}>
                            {sentence.trim()}
                            {index === array.length - 1 ? (
                              <img
                                src={sad}
                                alt="Sad"
                                className="claro__payment__process__error__imageEmoji"
                              />
                            ) : (
                              '.'
                            )}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                  )}

                  {(responseService.code === FAIL_SERVER_CLARO_PAY.code ||
                    responseService.code === PAYMENT_TIMEOUT_BI.code) && (
                    <p className="claro__payment__process__generalMessage">
                      {responseService.description
                        .split('.')
                        .map((sentence, index, array) => (
                          <React.Fragment key={sentence.trim()}>
                            {sentence.trim()}
                            {index === array.length - 1 ? (
                              <img
                                src={sad}
                                alt="Sad"
                                className="claro__payment__process__error__imageEmoji"
                              />
                            ) : (
                              '.'
                            )}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                  )}

                  {(responseService.code === INSUFFICIENT_FUNDS_BI.code ||
                    responseService.code === ACCOUNT_INACTIVE_BI.code) && (
                    <p className="claro__payment__process__generalMessage">
                      {responseService.description}
                      <br />
                      <br />
                      {REGRESAR_BI}
                    </p>
                  )}

                  {responseService.code === TRANSACTION_DECLINED.code && (
                    <p className="claro__payment__process__generalMessage">
                      Tu medio de pago ha sido <br /> rechazado por el banco.
                      <br />
                      <br />
                      Por favor revisa que los datos <br />
                      ingresados sean correctos.
                    </p>
                  )}

                  {responseService.code === WITHOUT_PROVISIONING_BI.code && (
                    <>
                      <p className="claro__payment__process__generalMessage">
                        No Logramos Procesar el
                        <br />
                        Paquete/Recarga que compraste.
                      </p>
                      <br />
                      <p className="claro__payment__process__generalMessage">
                        En un momento procedemos a <br />
                        devolverte el dinero de la compra.
                      </p>
                    </>
                  )}

                  {responseService.code === CONNECTION_FAILURE_BI.code && (
                    <p className="claro__payment__process__generalMessage">
                      Tenemos un Error de Conexión con el Banco. Por favor,
                      intenta más tarde.
                    </p>
                  )}

                  {responseService.code === ERROR_LIMIT_BI.code && (
                    <>
                      <p className="claro__payment__process__generalMessage">
                        En este momento no fue posible el pago con tu Banco.
                      </p>
                      <br />
                      <p className="claro__payment__process__generalMessage">
                        Presiona el Botón “Regresar” para otro método de pago.
                      </p>
                    </>
                  )}
                </div>

                <PaymentActionsNext
                  textBtn={responseService.title_button}
                  onClick={handleRedirect}
                  enabled={true}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <>
            {iIsVisibleAddNewAccountBi && (
              <>
                {requestId ? (
                  <div className="claro__payment__process__redirectBi">
                    <div className="claro__payment__process__redirectBi__information">
                      <p className="claro__payment__process__generalMessage">
                        Para continuar con tu Pago, es necesario que tengas la
                        última versión del APP de tu Banco...
                      </p>
                      <a
                        href={urltiendaApps}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="claro__payment__process__imagelogoBi"
                          src={logoBi}
                          alt="logoBi"
                        />
                      </a>
                      <p className="claro__payment__process__generalMessage">
                        O bien puedes instalar el APP ingresando a:
                      </p>
                      <a
                        href={urltiendaApps}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickTiendaApps}
                      >
                        <img
                          className="claro__payment__process__redirectBi__information__imageTiendasApps"
                          src={tiendasApps}
                          alt="tiendasApps"
                        />
                      </a>
                    </div>
                    <div className="claro__payment__process__redirectBi__actions">
                      {deepLinkVisible && (
                        <a
                          className="claro__payment__process__redirectBi__actions__btnIrApp"
                          href={urlAccountBi}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleClickBi}
                        >
                          <img
                            className="claro__payment__process__redirectBi__actions__logoBi"
                            src={logoBiButton}
                            alt="logoBiButton"
                          />
                          Ir al APP
                        </a>
                      )}

                      <CustomButton
                        text="Regresar"
                        onClick={handleRedirectBack}
                        customClass={
                          'claro__payment__process__redirectBi__actions__btnRegresar'
                        }
                        enabled={canClickButtonRegresarBi}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="claro__payment__process__waiting">
                    <img
                      className="claro__payment__process__waiting__imageBi"
                      src={processingPaymentImage}
                      alt="waiting"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Estamos procesando la información de pago con tu Banco
                    </p>
                    <img
                      className="claro__payment__process__imagelogoBi"
                      src={logoBi}
                      alt="logoBi"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Esto puede demorar, espera un momento...
                    </p>
                  </div>
                )}
              </>
            )}

            {selectAccountBi !== null && (
              <div className="claro__payment__process__waiting">
                <img
                  className="claro__payment__process__waiting__imageBi"
                  src={processingPaymentImage}
                  alt="waiting"
                />
                <p className="claro__payment__process__generalMessage">
                  Estamos procesando la información de pago con tu Banco
                </p>
                <img
                  className="claro__payment__process__imagelogoBi"
                  src={logoBi}
                  alt="logoBi"
                />
                <p className="claro__payment__process__generalMessage">
                  Esto puede demorar, espera un momento...
                </p>
              </div>
            )}

            {selectAccountBi === null && !iIsVisibleAddNewAccountBi && (
              <div className="claro__payment__process__waiting">
                <img
                  className="claro__payment__process__image"
                  src={processingPaymentImage}
                  alt="waiting"
                />
                <p className="claro__payment__process__generalMessage">
                  {PROCESSING_PAYMENT}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    )
  )
}

export default ClaroPayPaymentProcess
