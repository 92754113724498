import React, { useContext, useEffect, useState } from 'react'

import { Redirect } from 'react-router-dom'

import { RESPONSE_CODE } from '../../constants/responseCode'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import {
  MSISDN_HOME,
  AUTH_TOKENS_HOME,
} from '../../constants/ClaroPayConstants'

import ClaroPayContext from '../../context/ClaroPayContext'

import { getAxiosService } from '../../services/getAxiosService'
import { setLocalStorage } from '../../services/storage'

const ClaroHomeHe = () => {
  const { isAuthenticated, sendMetrics, setIsLoading } = useContext(
    ClaroPayContext,
  )

  const { OK } = RESPONSE_CODE

  const [redirect, setRedirect] = useState(false)

  const fetchData = async () => {
    try {
      const url = BACK_END_ROUTES.homehe
      const response = await getAxiosService(url)

      if (response.responseCode === OK.code) {
        setLocalStorage(AUTH_TOKENS_HOME, response.responseContent.accessToken)
        setLocalStorage(
          'refreshTokenHome',
          response.responseContent.refreshToken,
        )
        setLocalStorage(MSISDN_HOME, response.responseContent.msisdn)
        const metricsData = {
          metricType: 'Login',
          metricName: 'Login_HE',
        }
        await sendMetrics(metricsData)
      } else {
        const metricsData = {
          metricType: 'Login',
          metricName: 'Login_HE-Error',
        }
        await sendMetrics(metricsData)
      }
    } catch (error) {}
    setIsLoading(false)
    setRedirect(true)
  }

  useEffect(() => {
    if (isAuthenticated) {
      setRedirect(true)
    } else {
      setIsLoading(true)
      fetchData()
    }
  }, [])

  return <> {redirect && <Redirect to="/home" />}</>
}

export default ClaroHomeHe
