import React from 'react'
import CustomButton from '../CustomButton'

const Login = ({
  authMsisdn,
  valueMsisdn,
  valueMsisdnValidated,
  valueCode,
  handleChangeMsisdn,
  handleChangeCode,
  confirmMsisdn,
  fixPhoneNumber,
  resendCode,
  confirmCode,
  isButtonEnabledMsisdn,
  isButtonEnabledCode,
  errorMessageMsisdn,
  errorMessageCode,
  responseDescriptionMsisdn,
  responseDescriptionCode,
  responseDescriptionMsisdnResend,
}) => {
  return (
    <div className="login">
      <div className="welcome">
        <div className="welcome__text">Hola!!!</div>
        <div className="welcome__image">
          <img src={authMsisdn} alt="authMsisdn" />
        </div>
      </div>

      <div className="content">
        <div className={`content__text ${valueMsisdnValidated && 'disabled'}`}>
          Para continuar con tu compra, es necesario que ingreses tu número de
          teléfono para la validación de tus datos:
        </div>

        <div className="content__input">
          <input
            id="phoneNumberInput"
            className={`content__input__input ${valueMsisdnValidated &&
              'disabled'}`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]{0,9}"
            placeholder="Teléfono"
            value={valueMsisdn}
            onChange={handleChangeMsisdn}
            onClick={handleChangeMsisdn}
            maxLength={8}
            disabled={valueMsisdnValidated}
            autoFocus={!valueMsisdnValidated}
          />
          {responseDescriptionMsisdn && (
            <span className="content__input__errorMessage">
              {responseDescriptionMsisdn}
            </span>
          )}
          {errorMessageMsisdn && (
            <div className="content__input__errorMessage">
              {errorMessageMsisdn}
            </div>
          )}
          <CustomButton
            text={!valueMsisdnValidated ? 'Continuar' : 'Corregir # Teléfono'}
            onClick={!valueMsisdnValidated ? confirmMsisdn : fixPhoneNumber}
            customClass={
              !valueMsisdnValidated
                ? 'content__input__button'
                : 'content__input__button__resend'
            }
            enabled={!valueMsisdnValidated ? isButtonEnabledMsisdn : true}
          />
        </div>

        <div className={`content__text ${!valueMsisdnValidated && 'disabled'}`}>
          Hemos enviado un SMS con un código de verificación a tu teléfono:
        </div>

        <div className="content__input">
          <input
            className={`content__input__input ${!valueMsisdnValidated &&
              'disabled'}`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]{0,9}"
            placeholder="Código de verificación"
            value={valueCode}
            onChange={handleChangeCode}
            onClick={handleChangeCode}
            maxLength={6}
            disabled={!valueMsisdnValidated}
          />
          {responseDescriptionCode && (
            <span className="content__input__errorMessage">
              {responseDescriptionCode}
            </span>
          )}
          {errorMessageCode && (
            <div className="content__input__errorMessage">
              {errorMessageCode}
            </div>
          )}

          {valueMsisdnValidated && (
            <>
              <CustomButton
                text="Reenviar código"
                onClick={resendCode}
                customClass={'content__input__button__resend'}
                enabled={true}
              />
              {responseDescriptionMsisdnResend && (
                <span className="content__input__resendMessage">
                  {responseDescriptionMsisdnResend}
                </span>
              )}
              <CustomButton
                text="Continuar"
                onClick={confirmCode}
                customClass={'content__input__button'}
                enabled={isButtonEnabledCode}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login
