import localRoutes from './routes'

export const RESPONSE_CODE = {
  OK: {
    code: 0,
    description: 'Este es un teléfono prepago',
  },
  TIMEOUT: {
    code: 2,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  REENVIO_SMS: {
    code: 0,
    description: 'Código Reenviado Exitosamente',
  },
  OTRO_OPERADOR: {
    code: 1011,
    description: 'El Número que ingresaste no es válido',
  },
  NO_PREPAGO: {
    code: 1012,
    description:
      'Introduce tu número prepago de 8 dígitos y pulsa, “Continuar”',
  },
  ANTIFRAUDE: {
    code: 1013,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  BLACKLIST: {
    code: 1014,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  ANTIFRAUDE_KO: {
    code: 1015,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  CODIGO_NO_VALIDO: {
    code: 1021,
    description: 'El código ingresado es inválido',
  },
  CODIGO_EXPIRADO: {
    code: 1022,
    description: 'El código a expirado',
  },
  NO_REFRESH_TOKEN: {
    code: 1041,
    description:
      'Tu sesión ha caducado, por tu seguridad vuelve a introducir tu número de móvil',
  },
  CC_NOT_VALID_1: {
    code: 1101,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  CC_NOT_VALID_2: {
    code: 1102,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  CC_NOT_VALID_3: {
    code: 1103,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  CC_NOT_VALID_4: {
    code: 1104,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  CC_NOT_VALID_5: {
    code: 1105,
    description:
      'La tarjeta de credito que ha introducido no es valida, intentelo de nuevo.',
  },
  PROVISION_SERVICE_BEFORE: {
    code: 1110,
    description: 'Provisión del servicio no disponible',
    title_button: 'Reintentar',
    img: 'img/unknowError.svg',
  },
  PRODUCT_NOT_FOUND: {
    code: 1111,
    description: 'Provisión del servicio no disponible',
  },
  ERROR_PRECHARGE: {
    code: 1112,
    description: 'Provisión del servicio no disponible',
  },
  PAYMENT_UNAVAILABLE: {
    code: 1113,
    description: 'Pago no disponible',
  },
  PAYMENT_SERVICE_ERROR: {
    code: 1114,
    description:
      'Tu medio de pago ha sido rechazado por el banco. Por favor revisa que los datos ingresados sean correctos.',
    title_button: 'Regresar a mi compra',
    img: 'img/declinedCard.svg',
  },
  CHARGE_NOT_CONFIRMED: {
    code: 1120,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  PROVISION_SERVICE_AFTER: {
    code: 1121,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  ERROR_INTENTOS: {
    code: 1429,
    description:
      'Te has logueado varias veces. Por seguridad, debes esperar 10 segundos para intentar nuevamente.',
  },
  FALLO_SERVER: {
    code: 500,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  FALLO_SERVER_2: {
    code: 504,
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  ERROR_CODE: {
    code: 1501,
    description: 'El Número que ingresaste no es válido.',
  },
  NO_CODE: {
    description:
      'Lo sentimos, en este momento el servicio no está disponible, prueba mas tarde.',
  },
  OK_CLARO_PAY: {
    code: 0,
    description: '🙌 Tu compra fue realizada correctamente',
    title_button: 'Ver detalles',
    path: localRoutes.routesClaroPay.claroPayDetail,
    img: 'img/successfulPayment.svg',
  },
  SESSION_CODE_EXPIRED: {
    code: 1031,
    description: 'Existe un problema en este momento intenta nuevamente.',
    title_button: 'Regresar a mi compra',
    path: null,
    img: 'img/unknowError.svg',
  },
  ANTIFRAUD_LOCK: {
    code: 1032,
    description:
      'Has superado la cantidad de intentos permitida. Recuerda que puedes utilizar este servicio nuevamente',
    title_button: 'Regresar a mi compra',
    path: null,
    img: 'img/timesAllowed.svg',
  },
  TRANSACTION_DECLINED: {
    code: 1033,
    description:
      'Tu medio de pago ha sido rechazado por el banco. Por favor revisa que los datos ingresados sean correctos.',
    title_button: 'Regresar a mi compra',
    path: localRoutes.routesClaroPay.claroPayPaymentOptions,
    img: 'img/declinedCard.svg',
  },
  VALIDATION_CODE_EXPIRED: {
    code: 1034,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Reintentar',
    path: null,
    img: 'img/unknowError.svg',
  },
  VALIDATION_EXPIRATION_DATE_ERROR: {
    code: 1035,
    description:
      'La fecha de expiración es inválida. Por favor intenta nuevamente.',
    title_button: 'Ingresar Fecha Nuevamente',
    path: localRoutes.routesClaroPay.claroPayPaymentOptions,
    img: 'img/cvvError.svg',
  },
  FAIL_SERVER_CLARO_PAY: {
    code: 9000,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Reintentar',
    path: null,
    img: 'img/unknowError.svg',
  },
  VALIDATION_NO_LONGER_HAVE_DEBT: {
    code: 1037,
    description: 'La deuda ya ha sido pagada.',
    title_button: 'Regresar a mi compra',
    path: null,
    img: 'img/unknowError.svg',
  },
  DATOS_INCORRECTOS: {
    code: 1502,
    description:
      'Tu tarjeta ha sido rechazada por el banco. Por favor revisa que los datos ingresados sean correctos.',
    title_button: 'Regresar a mi compra',
    path: null,
    img: 'img/declinedCard.svg',
  },
  FAIL_SERVER_CLARO_BILLING: {
    code: 1034,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente 😞',
    title_button: 'Regresar a mi factura',
    path: null,
    img: 'img/unknowError.svg',
  },
  OK_CLARO_BILLING: {
    code: 0,
    description: 'Ya puedes revisar tu bandeja de entrada de tu correo.',
    title_button: null,
    path: null,
    img: 'img/successfulBilling.svg',
  },
  SESSION_CODE_EXPIRED_BILLING: {
    code: 1031,
    description:
      'Ha expirado el tiempo para completar tus datos de facturación.',
    title_button: 'Ir a Página principal',
    path: null,
    img: 'img/sessionExpiredBilling.svg',
  },
  BILLING_HAS_ALREADY_BEEN_GENERATED: {
    code: 1038,
    description:
      'Ya habías completados tus datos para facturación. Revisa en tu bandeja de entrada de tu correo.',
    title_button: 'Ir a Página principal',
    path: null,
    img: 'img/alreadyBilled.svg',
  },
  OK_VALIDATION_BILLING: {
    code: 0,
    description: 'Número de identificación valido',
    title_button: null,
    path: null,
    img: 'img/successfulBilling.svg',
  },
  TIMEOUT_VALIDATION_BILLING: {
    code: 2,
    description:
      'Existe un problema en el tiempo de respuesta, por favor intenta nuevamente 😞',
    title_button: null,
    path: null,
    img: 'img/unknowError.svg',
  },
  REJECT_VALIDATION_BILLING: {
    code: 6,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente 😞',
    title_button: 'Regresar a mi factura',
    path: null,
    img: 'img/unknowError.svg',
  },
  NO_RESPONSE_FROM_PROVIDER_BILLING: {
    code: 7,
    description:
      'Existe un problema en este momento con el envio de la factura 😞',
    title_button: 'Regresar a mi factura',
    path: null,
    img: 'img/unknowError.svg',
  },
  OK_ADM: {
    code: 0,
    description:
      '🙌 Tu Claro Te Presta fue activado correctamente, y se descontará de tu siguiente recarga.',
    title_button: 'Regresar',
    img: 'img/successfulPayment.svg',
  },
  UNKNOWN_ADM: {
    code: 1034,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Reintentar',
    img: 'img/unknowError.svg',
  },
  WALLET_RECOVERY_ADM: {
    code: 1039,
    description: '',
    title_button: 'Continuar',
    img: 'img/warningAdm.svg',
  },
  CON_SALDO_ADM: {
    code: 1040,
    description: 'Aún tienes Saldo Disponible',
    title_button: 'Reintentar',
    img: 'img/conSaldoAdm.svg',
  },
  SESSION_CODE_EXPIRED_ADM: {
    code: 1031,
    description: 'Session code expired',
    title_button: null,
    img: 'img/unknowError.svg',
  },
  NOT_PERMISSIONS_ADM: {
    code: 1042,
    description: '',
    title_button: 'Continuar',
    img: 'img/homeAdm.svg',
  },
  NOT_LOCATED_ANSWER_ADM: {
    code: 1115,
    description: 'Página no encontrada.',
    title_button: 'Regresar',
    img: 'img/unknowError.svg',
  },
  SESSION_UNDEFINED_ADM: {
    code: 1116,
    description: 'Página no encontrada.',
    title_button: 'Regresar',
    img: 'img/unknowError.svg',
  },
  INSUFFICIENT_FUNDS_BI: {
    code: 1115,
    description:
      'Tu cuenta no tiene fondos suficientes para completar esta solicitud.',
    title_button: 'Regresar',
    img: 'img/unknowError.svg',
  },
  ACCOUNT_INACTIVE_BI: {
    code: 1116,
    description: 'Tu cuenta no está activa o es inválida.',
    title_button: 'Regresar',
    img: 'img/warningAdm.svg',
  },
  WITHOUT_PROVISIONING_BI: {
    code: 1117,
    description:
      'No Logramos Procesar el Paquete/Recarga que compraste. En un momento procedemos a devolverte el dinero de la compra.',
    title_button: 'Regresar',
    img: 'img/withoutProvisioning.svg',
  },
  CONNECTION_FAILURE_BI: {
    code: 1118,
    description:
      'Tenemos un Error de Conexión con el Banco. Por favor, intenta más tarde.',
    title_button: 'Regresar',
    img: 'img/connectionFailure.svg',
  },
  DOES_NOT_APPLY: {
    code: 1052,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Regresar',
    img: 'img/unknowError.svg',
  },
  TOO_MANY_REQUESTS: {
    code: 429,
    description:
      'Te has logueado varias veces. Por tu seguridad, debes esperar 15 minutos',
  },
  ERROR_GENERAL_DELETE_ACCOUNT_BI: {
    code: 1022,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Reintentar',
    img: 'img/unknowError.svg',
  },
  ERROR_LIMIT_BI: {
    code: 1119,
    description: 'En este momento no fue posible el pago con tu Banco.',
    title_button: 'Regresar',
    img: 'img/unknowError.svg',
  },
  PAYMENT_TIMEOUT_BI: {
    code: 1126,
    description:
      'Existe un problema en este momento, por favor intenta nuevamente',
    title_button: 'Reintentar',
    path: null,
    img: 'img/unknowError.svg',
  },
}
