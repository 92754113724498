import React, { useEffect, useState } from 'react'

import { labels } from '../../constants/ClaroPayConstants'

import PaymentActionsNext from '../../components/PaymentActionsNext'

import unknowError from '../../assets/img/unknowError.svg'

const { REGRESAR } = labels

const ClaroFailureBi = () => {
  const [queryParametro, setQueryParametro] = useState('')

  useEffect(() => {
    const parametrosURL = new URLSearchParams(window.location.search)

    let query = ''

    for (const [key, value] of parametrosURL.entries()) {
      if (value) {
        query += `${key}=${value}`
      } else {
        query += key
      }
    }

    let url = ''

    if (query !== '') {
      if (query && !query.startsWith('/')) {
        query = '/' + query
      }

      setQueryParametro(query)

      url = window.location.origin + query
    } else {
      url = window.location.origin + '/home'
    }
    setTimeout(() => {
      window.location.href = url
    }, 4000)
  }, [])

  const handleRedirect = () => {
    window.location.href = window.location.origin + queryParametro
  }

  return (
    <div className="claro__payment__process">
      <div className="claro__payment__process__error">
        <img src={unknowError} alt="error" />
        <p className="claro__payment__process__generalMessage">
          En este momento no hemos podido completar el proceso de Afiliación,
          por favor, intenta más tarde.
        </p>

        <p className="claro__payment__process__generalMessage">
          Presiona el Botón “Regresar” para otro método de pago.
        </p>

        <PaymentActionsNext
          textBtn={REGRESAR}
          onClick={handleRedirect}
          enabled={true}
        />
      </div>
    </div>
  )
}

export default ClaroFailureBi
