import { clearLocalStorage } from '../../services/storage'

import {
  MSISDN_ADM,
  AUTH_TOKENS_ADM,
  PRODUCT_AUTH_ADM,
  UUID_PROCESS_ADM,
  CODE_RECURRENCE,
  CODE_CALLED_ME,
  CODE_BNPL,
  CODE_WALLET_RECOVERY,
  CODE_CBK_USSD,
  CODE_BILLING,
  CODE_DEMOBI,
  CODE_SESSIONBI,
  CODE_DELETEACCOUNTBI,
  CODE_SEND_A_RECHARGE,
  CODE_ADM,
  URL_KEY_TO_LOCALSTORAGE_CALLED_ME,
  URL_KEY_TO_LOCALSTORAGE_BNPL,
  URL_KEY_TO_LOCALSTORAGE_WALLET_RECOVERY,
  URL_KEY_TO_LOCALSTORAGE_CBK_USSD,
  URL_KEY_TO_LOCALSTORAGE_BILLING,
  URL_KEY_TO_LOCALSTORAGE_ADM,
  URL_KEY_TO_LOCALSTORAGE_SESSIONBI,
  URL_KEY_TO_LOCALSTORAGE_DELETEACCOUNTBI,
  URL_KEY_TO_LOCALSTORAGE_RECURRENCE,
  URL_KEY_TO_LOCALSTORAGE_SEND_A_RECHARGE,
  URL_KEY_TO_LOCALSTORAGE_DEMOBI,
  PRODUCT_SELECTED_HOME,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'

export const clearStorageLandings = () => {
  clearLocalStorage(MSISDN_ADM)
  clearLocalStorage(AUTH_TOKENS_ADM)
  clearLocalStorage(PRODUCT_AUTH_ADM)
  clearLocalStorage(UUID_PROCESS_ADM)

  clearLocalStorage(CODE_RECURRENCE)
  clearLocalStorage(CODE_CALLED_ME)
  clearLocalStorage(CODE_BNPL)
  clearLocalStorage(CODE_WALLET_RECOVERY)
  clearLocalStorage(CODE_CBK_USSD)
  clearLocalStorage(CODE_DELETEACCOUNTBI)
  clearLocalStorage(CODE_BILLING)
  clearLocalStorage(CODE_SEND_A_RECHARGE)
  clearLocalStorage(CODE_DEMOBI)
  clearLocalStorage(CODE_ADM)
  clearLocalStorage(CODE_SESSIONBI)

  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_RECURRENCE)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_CALLED_ME)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_BNPL)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_WALLET_RECOVERY)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_CBK_USSD)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_DELETEACCOUNTBI)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_BILLING)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_SEND_A_RECHARGE)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_DEMOBI)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_ADM)
  clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_SESSIONBI)

  clearLocalStorage(PRODUCT_SELECTED_HOME)
  clearLocalStorage(PAYMENT_PROCESS)
}
