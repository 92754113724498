import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { RESPONSE_CODE } from '../../constants/responseCode'
import { labels } from '../../constants/ClaroPayConstants'

import handleRedirectHome from '../../components/HandleRedirectHome'
import PaymentActionsNext from '../../components/PaymentActionsNext'

import processingBillingImage from '../../assets/img/processingPayment.svg'

const ClaroBillingProcess = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const {
    setResponseServiceBilling,
    responseServiceBilling,
    processingBilling,
    setProcessingBilling,
    sendMetrics,
    getImage,
    billingData,
    walletRecoveryData,
    cbkUssdData,
    homeData,
    callMeData,
    recurrenceData,
    sendARechargeData,
  } = claroPayContext

  const {
    OK_CLARO_BILLING,
    SESSION_CODE_EXPIRED_BILLING,
    BILLING_HAS_ALREADY_BEEN_GENERATED,
    REJECT_VALIDATION_BILLING,
    NO_RESPONSE_FROM_PROVIDER_BILLING,
    FAIL_SERVER_CLARO_BILLING,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const { PROCESSING_BILLING, HOMEPAGE } = labels

  useEffect(() => {
    if (billingData) {
      const metricsData = {
        metricType: 'Completar Datos_Proceso',
        metricName: 'Completar Datos_Proceso',
      }
      sendMetrics(metricsData)
    }
  }, [])

  const handleRedirect = () => {
    setProcessingBilling(false)
    setResponseServiceBilling(false)
    history.go(-1)
  }

  const isAnyDataPresent = () => {
    return (
      walletRecoveryData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  const codesWithoutRedirect = [
    REJECT_VALIDATION_BILLING.code,
    FAIL_SERVER_CLARO_BILLING.code,
    FAIL_SERVER_CLARO_PAY.code,
    NO_RESPONSE_FROM_PROVIDER_BILLING.code,
  ]

  const sendMetricsResponse = response => {
    if (billingData) {
      const metricsDataDefault = {
        metricType: 'Completar Datos_Error',
        metricName: 'Completar Datos_Error',
      }

      const metricsMapping = {
        [OK_CLARO_BILLING.code]: {
          metricType: 'Completar Datos_OK',
          metricName: 'Completar Datos_OK',
        },
        [SESSION_CODE_EXPIRED_BILLING.code]: {
          metricType: 'ExpiradoTiempo',
          metricName: 'ExpiradoTiempo',
        },
        [BILLING_HAS_ALREADY_BEEN_GENERATED.code]: {
          metricType: 'CompletadoDatosAnterior',
          metricName: 'CompletadoDatosAnterior',
        },
      }

      const metricsData = metricsMapping[response.code] || metricsDataDefault
      sendMetrics(metricsData)
    } else if (isAnyDataPresent()) {
      if (response.code === OK_CLARO_BILLING.code) {
        const metricsData = {
          metricType: 'Exitoso_FEL',
          metricName: 'Exitoso_FEL',
        }
        sendMetrics(metricsData)
      } else if (codesWithoutRedirect.includes(response.code)) {
        const metricsData = {
          metricType: 'ExitosoFel',
          metricName: 'FEL-CompletarDatos_Error',
        }
        sendMetrics(metricsData)
      }
    }
  }

  useEffect(() => {
    if (processingBilling && responseServiceBilling !== null) {
      sendMetricsResponse(responseServiceBilling)
      if (!codesWithoutRedirect.includes(responseServiceBilling.code)) {
        const timeoutId = setTimeout(() => {
          handleRedirectHome()
        }, 6000)

        return () => clearTimeout(timeoutId)
      }
    }
  }, [responseServiceBilling])

  return (
    processingBilling && (
      <div className="claro__payment__process">
        {responseServiceBilling !== null ? (
          <>
            {responseServiceBilling.code === OK_CLARO_BILLING.code && (
              <div className="claro__payment__process__sucess">
                <div className="claro__payment__process__sucess__information__general">
                  <img
                    className="claro__payment__process__image"
                    src={getImage(responseServiceBilling.img)}
                    alt="sucess"
                  />
                  <p className="claro__payment__process__sucess__information__general__titleBilling">
                    Listo!!!
                    <br />
                    Factura Enviada
                  </p>

                  <p className="claro__payment__process__sucess__information__general__subtitleBilling">
                    {responseServiceBilling.description}
                  </p>
                </div>
                <PaymentActionsNext
                  textBtn={HOMEPAGE}
                  onClick={handleRedirectHome}
                  enabled={true}
                />
              </div>
            )}

            {(responseServiceBilling.code === FAIL_SERVER_CLARO_BILLING.code ||
              responseServiceBilling.code ===
                NO_RESPONSE_FROM_PROVIDER_BILLING.code ||
              responseServiceBilling.code === REJECT_VALIDATION_BILLING.code ||
              responseServiceBilling.code === FAIL_SERVER_CLARO_PAY.code ||
              responseServiceBilling.code ===
                SESSION_CODE_EXPIRED_BILLING.code ||
              responseServiceBilling.code ===
                BILLING_HAS_ALREADY_BEEN_GENERATED.code) && (
              <>
                <div className="claro__payment__process__error">
                  {responseServiceBilling.img && (
                    <img
                      className="claro__payment__process__image"
                      src={getImage(responseServiceBilling.img)}
                      alt="error"
                    />
                  )}
                  <p className="claro__payment__process__generalMessage">
                    {responseServiceBilling.description}
                  </p>
                </div>

                <PaymentActionsNext
                  textBtn={responseServiceBilling.title_button}
                  onClick={() => {
                    if (
                      responseServiceBilling.code ===
                        SESSION_CODE_EXPIRED_BILLING.code ||
                      responseServiceBilling.code ===
                        BILLING_HAS_ALREADY_BEEN_GENERATED.code
                    ) {
                      handleRedirectHome()
                    } else {
                      handleRedirect()
                    }
                  }}
                  enabled={true}
                />
              </>
            )}
          </>
        ) : (
          <div className="claro__payment__process__waiting">
            <img
              className="claro__payment__process__image"
              src={processingBillingImage}
              alt="waiting"
            />
            <p className="claro__payment__process__generalMessage">
              {PROCESSING_BILLING}
            </p>
          </div>
        )}
      </div>
    )
  )
}

export default ClaroBillingProcess
