import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { labels } from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentAccountBi from '../ClaroPayPaymentAccountBi'

import PaymentActionsPayment from '../../components/PaymentActionsPayment'
import { generarUuid } from '../../components/GenerarUuid'

import up from '../../assets/img/up-arrow.png'
import down from '../../assets/img/down-arrow.png'

const ClaroWalletRecovery = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const { VIEW_MORE_BENEFITS, VIEW_LESS_BENEFITS } = labels

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    walletRecoveryData,
    payWithSavedCardWalletRecovery,
    payWithNewCardWalletRecovery,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    payWithAccountBiWalletRecovery,
    setIsVisibleAddNewAccountBi,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
    setWalletRecoveryData,
    getImage,
    setCurrentCategoryMetrics,
  } = claroPayContext

  const [state, setState] = useState({
    msisdn: '',
    debt: {
      amountOfDebt: 0,
      amountPayable: 0,
      dateOfPurchase: ',',
      currency: 0,
      productDescription: '',
    },
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    totalAmount: 0,
    totalAmountPayment: 0,
    alias: [],
    indicator: false,
    combo: true,
  })
  const [detallesDebt, setDetalleDebt] = useState(true)
  const [detallesCombo, setDetalleCombo] = useState(true)

  useEffect(() => {
    const { msisdn, debt, pack, alias, indicator, combo } = walletRecoveryData
    let totalAmount = debt.amountPayable + pack.amount
    let totalAmountPayment = combo
      ? debt.amountPayable + pack.amount
      : debt.amountPayable

    setCurrentCategoryMetrics(combo ? 'Combo-' : 'Recarga-')

    setState(prevState => ({
      ...prevState,
      msisdn,
      debt,
      pack,
      alias,
      totalAmount,
      totalAmountPayment,
      indicator,
      combo,
    }))
  }, [state.combo])

  useEffect(() => {
    const metricsData = {
      metricType: 'home',
      metricName: 'Home',
    }

    if (code) {
      sendMetrics(metricsData)
    }
  }, [code])

  const viewMoreInfoDebt = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'VerDetalle',
    }
    detallesDebt && sendMetrics(metricsData)
    setDetalleDebt(!detallesDebt)
  }

  const viewMoreInfoCombo = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'VerDetalle',
    }
    detallesCombo && sendMetrics(metricsData)
    setDetalleCombo(!detallesCombo)
  }

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardWalletRecovery(state.combo, uuid)
        : await payWithSavedCardWalletRecovery(state.combo, uuid)
    }
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiWalletRecovery(state.combo, uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(state.combo)
  }

  const handleCombo = active => {
    if (active) {
      const metricsData = {
        metricType: 'Seleccion',
        metricName: 'CheckOut_Combo-Seleccion',
      }
      sendMetrics(metricsData)
    } else {
      const metricsData = {
        metricType: 'Seleccion',
        metricName: 'CheckOut_Recarga-Seleccion',
      }
      sendMetrics(metricsData)
    }
    setState(prevState => ({
      ...prevState,
      combo: active,
    }))
    const newWalletRecoveryData = {
      ...walletRecoveryData,
      combo: active,
    }
    setWalletRecoveryData(newWalletRecoveryData)
    setDetalleCombo(true)
    setDetalleDebt(true)
  }

  const {
    pack,
    debt,
    combo,
    indicator,
    totalAmount,
    totalAmountPayment,
  } = state

  const icons = pack?.socialMedia?.[0]?.icons || []

  const textBtn = `Pagar ${debt.currency}${totalAmountPayment}`

  return (
    <section className="claro__container__walletRecovery">
      <div className="claro__container__walletRecovery__title">
        Selecciona la Opción para Pago de tu deuda de Claro Te Presta:
      </div>

      <div
        className={`claro__container__walletRecovery__select ${
          combo ? '' : 'container__inactive'
        }`}
        onClick={combo ? undefined : () => handleCombo(true)}
      >
        <div
          className={`claro__container__walletRecovery__select__title ${
            combo ? 'active' : 'inactive'
          }`}
        >
          <div className="claro__container__walletRecovery__select__title__content">
            <div className="title">Superpack + Pago Deuda Pendiente</div>
            <div className="subtitle">
              {pack.subtitle} - {pack.validity}
            </div>
          </div>
        </div>

        {detallesCombo ? (
          <div className="claro__container__walletRecovery__select__content">
            <div className="title">Total a Pagar</div>
            <div className="value">
              {debt.currency}
              {totalAmount}
            </div>
          </div>
        ) : (
          <div className="details">
            <div className="debt">
              <div className="content">
                <div className="description">{debt.productDescription}</div>
                <div className="amount">
                  {debt.currency}
                  {debt.amountPayable}
                </div>
              </div>
              <div className="title__debt">
                <p>Pagas tu Préstamo Pendiente</p>
                <p>En Claro Te Presta</p>
              </div>
              <div className="info__debt">
                <p>Fecha de Compra: {debt.dateOfPurchase}</p>
                <p>
                  Monto de la Deuda: {debt.currency}
                  {debt.amountPayable}
                </p>
              </div>
            </div>

            {pack && (
              <div className="pack">
                <div className="content">
                  <div className="title">{pack.title}</div>
                  <div className="amount">
                    {pack.currency}
                    {pack.amount}
                  </div>
                </div>
                <div className="subtitle">
                  {pack.subtitle} - {pack.validity}
                </div>
                <div className="subtitle">{pack.mainDescription}</div>
                <div className="socialMedia">
                  {icons.map(item => (
                    <img key={item} src={getImage(item)} alt="social-icon" />
                  ))}
                </div>
              </div>
            )}

            <div className="total-details">
              <div className="content">
                <div className="title">Total a pagar</div>
                <div className="amount">
                  {debt.currency}
                  {totalAmount}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`view__more ${
          combo ? 'view__more__active' : 'view__more__inactive'
        }`}
        onClick={combo ? viewMoreInfoCombo : null}
      >
        {detallesCombo ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}
        <img
          src={`${detallesCombo ? down : up}`}
          className=""
          style={{
            width: '20px',
            filter: combo ? '' : 'grayscale(100%)',
          }}
          alt={detallesCombo ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}
        />
      </div>

      <div
        className={`claro__container__walletRecovery__select ${
          combo ? 'container__inactive' : ''
        }`}
        onClick={combo ? () => handleCombo(false) : undefined}
      >
        <div
          className={`claro__container__walletRecovery__select__title ${
            combo ? 'inactive' : 'active'
          }`}
        >
          <div className="claro__container__walletRecovery__select__title__content">
            <div className="title">Pago Deuda Pendiente</div>
          </div>
        </div>

        {detallesDebt ? (
          <div className="claro__container__walletRecovery__select__content">
            <div className="title">Total a Pagar</div>
            <div className="value">
              {debt.currency}
              {debt.amountPayable}
            </div>
          </div>
        ) : (
          <div className="details">
            <div className="debt">
              <div className="content">
                <div className="description">{debt.productDescription}</div>
                <div className="amount">
                  {debt.currency}
                  {debt.amountPayable}
                </div>
              </div>
              <div className="title__debt">
                <p>Pagas tu Préstamo Pendiente</p>
                <p>En Claro Te Presta</p>
              </div>
              <div className="info__debt">
                <p>Fecha de Compra: {debt.dateOfPurchase}</p>
                <p>
                  Monto de la Deuda: {debt.currency}
                  {debt.amountPayable}
                </p>
              </div>
            </div>

            <div className="total-details">
              <div className="content">
                <div className="title">Total a pagar</div>
                <div className="amount">
                  {debt.currency}
                  {debt.amountPayable}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`view__more ${
          combo ? 'view__more__inactive' : 'view__more__active'
        }`}
        onClick={combo ? null : viewMoreInfoDebt}
      >
        {detallesDebt ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}
        <img
          src={`${detallesDebt ? down : up}`}
          className=""
          style={{
            width: '20px',
            filter: combo ? 'grayscale(100%)' : '',
          }}
          alt={detallesDebt ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}
        />
      </div>

      <div className="claro__payment__methods">
        {countryCode === '507' ? (
          <ClaroPayPaymentAccountBi />
        ) : (
          <ClaroPayPaymentOptions />
        )}
      </div>

      <PaymentActionsPayment
        selectAccountBi={selectAccountBi}
        iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
        indicator={indicator}
        countryCode={countryCode}
        textBtn={textBtn}
        handlePaymentNewAccountBi={handlePaymentNewAccountBi}
        handlePaymentAccountBi={handlePaymentAccountBi}
        canClickButton={canClickButton}
        handlePayment={handlePayment}
        isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
        isEnabledPayWithNewCard={isEnabledPayWithNewCard}
        isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
      />
    </section>
  )
}

export default ClaroWalletRecovery
