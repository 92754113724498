export async function encryptData(data) {
  const importPublicKey = async () => {
    const publicKeyPEM = process.env.REACT_APP_DATA
    const binaryDerString = window.atob(publicKeyPEM)
    const binaryDer = new Uint8Array(
      [...binaryDerString].map(char => char.charCodeAt(0)),
    )
    return window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      ['encrypt'],
    )
  }

  const ab2str = buf => String.fromCharCode.apply(null, new Uint8Array(buf))

  const publicKey = await importPublicKey()
  const encoded = new TextEncoder().encode(data)
  const encrypted = await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    publicKey,
    encoded,
  )
  return window.btoa(ab2str(encrypted))
}
