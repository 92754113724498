import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import Country from '../../constants/Country'

import imageGT from '../../assets/img/tyc-ADM-GT.png'
import imageHN from '../../assets/img/tyc-ADM-HN.png'
import imageNI from '../../assets/img/tyc-ADM-NI.png'
import imageCR from '../../assets/img/tyc-ADM-CR.png'

import imageSVRecarga1 from '../../assets/img/tyc-ADM-SV_R1.png'
import imageSVRecarga2 from '../../assets/img/tyc-ADM-SV_R2.png'
import imageSVRecarga3 from '../../assets/img/tyc-ADM-SV_R3.png'
import imageSVPaquete1 from '../../assets/img/tyc-ADM-SV_P1.png'
import imageSVPaquete2 from '../../assets/img/tyc-ADM-SV_P2.png'
import imageSVPaquete3 from '../../assets/img/tyc-ADM-SV_P3.png'

const TyC_Handler = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const [descripcion, setDescripcion] = useState('')
  const [beneficiosOne, setBeneficiosOne] = useState('')
  const [usoServicioOne, setUsoServicioOne] = useState('')
  const [usoServicioThree, setUsoServicioThree] = useState('')
  const [aclaraciones, setAclaraciones] = useState('')
  const [imageCostoOne, setImageCostoOne] = useState('')

  const handleRedirect = () => {
    history.go(-1)
  }

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollUp()
    }, 500)

    if (countryCode === '502') {
      setDescripcion(
        'CLARO TE PRESTA es un servicio que TELECOMUNICACIONES DE GUATEMALA,S.A. (CLARO) ofrece a sus usuarios prepago activos, el cual les brinda la posibilidad de adquirir un paquete en modalidad de adelanto, cuyo valor será descontado en la próxima recarga de saldo o paquete que el usuario realice, más un cargo administrativo por uso del servicio.',
      )

      setBeneficiosOne(
        '1. Servicio en el momento oportuno. Si el usuario no tiene saldo y no tiene a disposición medios de pago ni puntos de venta autorizados, puede solicitar el servicio “Claro te presta” marcando al *743 o *555#, para lo cual aplican las condiciones y requisitos establecidos en estos términos y condiciones.',
      )

      setUsoServicioOne(
        'El usuario debe marcar *555# opción 8 (servicios), luego debe ingresar a la opción 2 (te presto saldo), posteriormente debe ingresar a la opción 1 para confirmar que desea continuar y elegir el paquete de “Claro te presta” que prefiera.',
      )

      setUsoServicioThree(
        '1. El costo de los paquetes se expresa en moneda local e incluyen IVA.',
      )

      setAclaraciones(
        'El Usuario podrá aclarar cualquier duda relacionada con el servicio “Claro te presta” contactando a servicio al cliente de CLARO en Guatemala al número telefónico 147-100.',
      )

      setImageCostoOne(imageGT)
    } else if (countryCode === '503') {
      setDescripcion(
        'CLARO TE PRESTA es un servicio que CTE TELECOM PERSONAL, S.A. DE C.V. (CLARO) ofrece a sus usuarios prepago activos, el cual les brinda la posibilidad de adquirir un paquete en modalidad de adelanto, cuyo valor será descontado en la próxima recarga de saldo o de paquete que el usuario realice.',
      )

      setBeneficiosOne(
        '1. Servicio en el momento oportuno. Si el usuario no tiene saldo y no tiene a disposición medios de pago ni puntos de venta autorizados, puede solicitar el servicio “Claro te presta” marcando al *743 o *555#, para lo cual aplican las condiciones y requisitos establecidos en estos términos y condiciones.',
      )

      setUsoServicioOne(
        'El usuario debe marcar *555# opción 5 (servicios sin saldo), luego debe ingresar a la opción 1 (Claro te presta), posteriormente debe elegir el paquete de “Claro te presta” que prefiera.',
      )
    } else if (countryCode === '504') {
      setDescripcion(
        'CLARO TE PRESTA es un servicio que SERCOM DE HONDURAS, S.A. DE C.V. (CLARO) ofrece a sus usuarios prepago activos, el cual les brinda la posibilidad de adquirir un paquete en modalidad de adelanto, cuyo valor será descontado en la próxima recarga de saldo o paquete que el usuario realice, más un cargo administrativo por uso del servicio.',
      )

      setBeneficiosOne(
        '1. Servicio en el momento oportuno. Si el usuario no tiene saldo y no tiene a disposición medios de pago ni puntos de venta autorizados, puede solicitar el servicio “Claro te presta” marcando al *743 o *777#, para lo cual aplican las condiciones y requisitos establecidos en estos términos y condiciones.',
      )

      setUsoServicioOne(
        'El usuario debe marcar *777# opción 5 (Préstamo), luego debe ingresar a la opcion 1 (Claro te presta), posteriormente debe ingresar y elegir el paquete de “Claro te presta” que prefiera. ',
      )

      setUsoServicioThree(
        '1. El costo de los paquetes se expresa en moneda local e incluyen ISV.',
      )

      setAclaraciones(
        'El Usuario podrá aclarar cualquier duda relacionada con el servicio “Claro te presta” contactando a servicio al cliente de CLARO en Honduras al número telefónico 2205-5555.',
      )

      setImageCostoOne(imageHN)
    } else if (countryCode === '505') {
      setDescripcion(
        'CLARO TE PRESTA es un servicio que EMPRESA NICARAGÜENSE DE TELECOMUNICACIONES, S.A. (CLARO) ofrece a sus usuarios prepago activos, el cual les brinda la posibilidad de adquirir un paquete en modalidad de adelanto, cuyo valor será descontado en la próxima recarga de saldo o paquete que el usuario realice, más un cargo administrativo por uso del servicio.',
      )

      setBeneficiosOne(
        'Servicio en el momento oportuno. Si el usuario no tiene saldo y no tiene a disposición medios de pago ni puntos de venta autorizados, puede solicitar el servicio “Claro te presta” marcando al *743 o *555#, para lo cual aplican las condiciones y requisitos establecidos en estos términos y condiciones.',
      )

      setUsoServicioOne(
        'El usuario debe marcar *555# opción 2 (Servicios Sin Saldo), luego debe ingresar a la opción 1 (Claro Te Presta), posteriormente debe ingresar a la opción que desea contratar para continuar y elegir el paquete de “Claro te presta” que prefiera.',
      )

      setUsoServicioThree(
        'El costo de los paquetes se expresa en moneda local e incluyen IVA.',
      )

      setAclaraciones(
        'El Usuario podrá aclarar cualquier duda relacionada con el servicio “Claro te presta” contactando a servicio al cliente de CLARO en Nicaragua al número telefónico 8859000.',
      )

      setImageCostoOne(imageNI)
    } else if (countryCode === '506') {
      setDescripcion(
        'CLARO TE PRESTA es un servicio que CLARO CR TELECOMUNICACIONES, S.A. (CLARO) ofrece a sus usuarios prepago activos, el cual les brinda la posibilidad de adquirir un paquete en modalidad de adelanto, cuyo valor será descontado en la próxima recarga de saldo o paquete que el usuario realice, más un cargo administrativo por uso del servicio.',
      )

      setBeneficiosOne(
        'Servicio en el momento oportuno. Si el usuario no tiene saldo y no tiene a disposición medios de pago ni puntos de venta autorizados, puede solicitar el servicio “Claro te presta” marcando al *743 o *555#, para lo cual aplican las condiciones y requisitos establecidos en estos términos y condiciones.',
      )

      setUsoServicioOne(
        'El usuario debe marcar *555# opción 8 (servicios), luego debe ingresar a la opción 2 (te presto saldo), posteriormente debe ingresar a la opción 1 para confirmar que desea continuar y elegir el paquete de “Claro te presta” que prefiera.',
      )

      setUsoServicioThree(
        'El costo de los paquetes se expresa en moneda local e incluyen IVA.',
      )

      setAclaraciones(
        'El Usuario podrá aclarar cualquier duda relacionada con el servicio “Claro te presta” contactando a servicio al cliente de CLARO en Costa Rica al número telefónico 7002-7002.',
      )

      setImageCostoOne(imageCR)
    }

    return () => clearTimeout(timer)
  }, [countryCode])

  return (
    <div className="claro__termsAndConditionsOrPrivacity__ADM">
      <div className="claro__termsAndConditionsOrPrivacity__ADM__goHome">
        <div onClick={handleRedirect}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.509"
            height="25.377"
            viewBox="0 0 14.509 25.377"
          >
            <path
              className="icon"
              id="Icon_ionic-ios-arrow-forward"
              data-name="Icon ionic-ios-arrow-forward"
              d="M21.382,18.88l-9.6-9.6a1.806,1.806,0,0,1,0-2.561,1.829,1.829,0,0,1,2.569,0L25.228,17.6a1.81,1.81,0,0,1,.053,2.5L14.356,31.044a1.814,1.814,0,0,1-2.569-2.561Z"
              transform="translate(25.756 31.573) rotate(180)"
              fill="#2f2f2f"
            />
          </svg>
          Regresar
        </div>
      </div>
      <div className="claro__termsAndConditionsOrPrivacity__ADM__title">
        Términos y Condiciones del Servicio
        <br />
        Claro Te Presta
      </div>

      <div className="claro__termsAndConditionsOrPrivacity__ADM__text">
        <p>
          <span> I. Descripción.</span>
          <br />
          {descripcion}
          <br /> <br />
        </p>

        <p>
          <span> II. Beneficios.</span>
          <br />
        </p>

        <ol>
          <li>{beneficiosOne}</li>
          <li>
            2. Servicio de libre aceptación. El paquete elegido “Claro te
            presta” será abonado únicamente previa autorización del usuario.
          </li>
        </ol>

        <p>
          <span>III. Condiciones y requisitos para optar al servicio.</span>
          <br />
          Para determinar qué tipo de paquete de datos se ofrece y aplica a cada
          usuario y que éste pueda hacer uso de él, se toman en cuenta las
          siguientes condiciones de la línea:
          <br />
        </p>

        <ol>
          <li>
            1. Segmento de la línea: El servicio está disponible únicamente para
            usuarios prepago Claro. No aplica para usuarios con planes híbridos
            o de consumo controlado.
          </li>
          {countryCode === '503' ? (
            <li>
              2. Actividad de la línea: La línea del usuario debe encontrarse
              activa.
            </li>
          ) : (
            <li>
              2. Actividad de la línea: La línea del usuario debe encontrarse
              activa, es decir, en la “Fase Activo del Ciclo de Vida de la
              Línea”.
            </li>
          )}
          <li>
            3. Frecuencia de Recarga: La línea debe contar con recargas
            constantes en los últimos tres meses.
          </li>
        </ol>

        <p>
          <span>IV. Proceso para hacer uso del servicio.</span>
          <br />
          <span>
            1. Claro te presta a través del canal USSD (Unstructured
            Supplementary Service Data).
          </span>
          <br />
          {usoServicioOne}
          <br /> <br />
          {countryCode === '503' ? (
            <>
              Al aceptar el paquete de “Claro te presta”, el usuario recibirá
              una notificación vía SMS en su terminal móvil indicándole el valor
              del paquete adelantado por CLARO y la vigencia de uso de este.
            </>
          ) : (
            <>
              Al aceptar el paquete de “Claro te presta”, el usuario recibirá
              una notificación vía SMS en su terminal móvil indicándole el costo
              del paquete adelantado por CLARO y la vigencia de uso del mismo.
            </>
          )}
          <br />
          <br />
          <span>
            2. Claro te presta a través de invitación al usuario vía menú de
            voz.
          </span>
          <br />
          Si el usuario realiza una llamada y el sistema identifica que no
          cuenta con saldo suficiente para realizar o continuar con la llamada,
          el sistema redirigirá al usuario a escuchar una locución invitándole a
          hacer uso del servicio de “Claro te presta”.
          <br /> <br />
          Los usuarios también tienen la opción de marcar directamente al *743 y
          solicitar el paquete de “Claro te presta” disponible.
          <br /> <br />
          <span>3. Costo del servicio y cobro.</span>
          <br />
        </p>

        {countryCode === '503' ? (
          <ol>
            <li>
              1. El costo de los paquetes se expresa en dólares americanos e
              incluyen IVA.
            </li>
            <li>
              2. El cobro del valor total del paquete adelantado, lo hará
              automáticamente el sistema en la próxima recarga de saldo o compra
              de paquete, que haga el usuario.
            </li>
            <li>
              3. Al hacer la recarga de saldo o compra de paquete, el usuario
              será notificado vía SMS en su terminal móvil sobre el valor
              debitado por el paquete “Claro te presta” anteriormente adquirido.
            </li>
            <li>4. Escenarios de cobro de “Claro te presta”:</li>
            <li>Pago por recarga:</li>
            <ol>
              <li>
                1. Recarga mayor a deuda: se realizará el cobro del valor total
                de la deuda adquirida con la recarga efectuada por el usuario.
                El remanente será acreditado en saldo. Ejemplo:
              </li>
              <li>
                <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                  <img src={imageSVRecarga1} alt="TYC-ADM" />
                </div>
              </li>
              <li>
                2. Recarga igual a deuda: se realizará el cobro del valor total
                de la deuda adquirida con la recarga efectuada por el usuario.
                No hay remanente, por lo que no se acreditará ningún valor en
                saldo. Ejemplo: El remanente será acreditado en saldo. Ejemplo:
              </li>
              <li>
                <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                  <img src={imageSVRecarga2} alt="TYC-ADM" />
                </div>
              </li>
              <li>
                3. Recarga menor a deuda: se realizará el cobro de la deuda
                adquirida con la recarga efectuada por el usuario, quedando
                pendiente el monto restante de la deuda en su próxima recarga.
                Ejemplo:
              </li>
              <li>
                <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                  <img src={imageSVRecarga3} alt="TYC-ADM" />
                </div>
              </li>
              <br />
            </ol>

            <li> Pago por compra de paquete:</li>
            <li>
              <ol>
                <li>
                  1. Monto de paquete mayor a deuda: se realizará el cobro total
                  de la deuda adquirida con el monto del paquete solicitado por
                  el usuario. Luego de que el sistema haya hecho el cobro del
                  monto adeudado, al resultar un remanente a favor del usuario,
                  se le acreditará el valor en saldo o un paquete sustituto por
                  el valor de dicho remanente o por uno de menor valor, en
                  función del paquete disponible, al cual le aplicarán las
                  condiciones propias del paquete. Ejemplos:
                </li>
                <li>
                  <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                    <img src={imageSVPaquete1} alt="TYC-ADM" />
                  </div>
                </li>
                <li>
                  2. Monto de paquete igual a deuda: se realizará el cobro total
                  de la deuda adquirida con el monto del paquete solicitado por
                  el usuario. Luego de que el sistema haya hecho el cobro por el
                  monto del paquete comprado, no hay remanente y la deuda
                  quedará saldada. No se acreditará un paquete sustituto.
                  Ejemplo:
                </li>
                <li>
                  <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                    <img src={imageSVPaquete2} alt="TYC-ADM" />
                  </div>
                </li>
                <li>
                  3. Monto de paquete menor a deuda: se realizará el cobro de la
                  deuda adquirida con el monto del paquete solicitado por el
                  usuario. Luego de que el sistema haya efectuado el cobro, no
                  habiendo remanente, quedará pendiente de pagar por parte del
                  cliente, el monto restante de la deuda en la compra de su
                  próximo paquete. No se acreditará saldo o paquete sustituto.
                </li>
                <li>
                  <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                    <img src={imageSVPaquete3} alt="TYC-ADM" />
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        ) : (
          <ol>
            <li>{usoServicioThree}</li>
            <li>
              2. El cobro del valor total del paquete adelantado lo hará
              automáticamente el sistema en la próxima recarga de saldo o compra
              de paquete que haga el usuario.
            </li>
            <li>
              3. Si después de que el sistema haya hecho el cobro del monto
              adelantado, resulta un saldo remanente a favor del usuario, se le
              acreditará un paquete sustituto por el valor de dicho remanente o
              por uno de menor valor, en función del tipo de paquete disponible,
              al cual le aplicarán las condiciones propias del paquete
              acreditado.
            </li>
            <li>
              4. Al hacer la recarga de saldo o compra de paquete el usuario
              será notificado vía SMS en su terminal móvil del valor debitado
              por el adelanto que se le habilitó en la compra de saldo o recarga
              anterior que realizó.
            </li>
            <li>
              5. Si el usuario realiza una recarga de saldo o compra un paquete
              con un valor menor al monto que se le debe descontar por el
              adelanto recibido, se realizará el débito fraccionado de acuerdo
              con el siguiente esquema de parcialidades:
            </li>
            <li>
              <div className="claro__termsAndConditionsOrPrivacity__ADM__text__image">
                <img src={imageCostoOne} alt="TYC-ADM" />
              </div>
            </li>
          </ol>
        )}

        <span> 4. Aclaraciones del usuario.</span>
        <br />
        {countryCode === '503' ? (
          <ol>
            <li>
              • El Usuario podrá aclarar cualquier duda relacionada con el
              servicio “Claro te presta” contactando a servicio al cliente de
              CLARO en El Salvador al número telefónico 503 2250-5555 o por
              Whatsapp 503 6060-5555.
            </li>
            <li>
              • El Usuario no podrá realizar prestamos hasta que haya cancelado
              la deuda pendiente que posea.
            </li>
            <li>
              • En caso de asignarse un paquete sustituto, éste estará
              relacionado con los beneficios del paquete comprado. Este paquete
              podrá variar en precio, beneficios y vigencia.
            </li>
          </ol>
        ) : (
          <p>{aclaraciones}</p>
        )}
      </div>
    </div>
  )
}

export default TyC_Handler
