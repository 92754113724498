import React from 'react'

import CategoriesOptions from '../CategoryOptions'
import { getImage } from '../../components/GetImage'
import useTouchHandler from '../../components/UseTouch'
import CarouselControls from '../CarouselControls'
import RecommendedPackage from '../RecommendedPackage'
import PaymentActionsNext from '../PaymentActionsNext'

const CarouselPackages = ({
  currentCategory,
  handleCategory,
  pack,
  packSig,
  packFinal,
  handleNextProduct,
  bundle,
  topup,
  currentPosition,
  handleSelectedProduct,
}) => {
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchHandler(
    handleNextProduct,
  )

  const icons = pack?.socialMedia?.[0]?.icons || []
  const { icons: iconsSig = [] } = packSig?.socialMedia?.[0] || {}
  const { icons: iconsFinal = [] } = packFinal?.socialMedia?.[0] || {}

  return (
    <>
      <CategoriesOptions
        currentCategory={currentCategory}
        handleCategory={handleCategory}
        bundle={bundle}
      />

      <div
        className="touch"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <RecommendedPackage pack={pack} currentCategory={currentCategory} />

        <div className="claro__recommended__package__content__min">
          {pack && (
            <div className="claro__recommended__package__min">
              <div
                className={`package__box__title ${
                  currentCategory === 'topup'
                    ? 'package__box__title__topup'
                    : 'package__box__title__bundle'
                }`}
              >
                <p>
                  {pack.title}
                  {currentCategory === 'bundle' ? (
                    <>{` ${pack.subtitle} - ${pack.validity}`}</>
                  ) : null}
                </p>
              </div>

              <div
                className={`package__box__content${
                  currentCategory === 'topup' ? '__topup' : '__bundle'
                }`}
              >
                {currentCategory === 'topup' && (
                  <div className="amount__topup">
                    {pack.currency}
                    {pack.amount}
                  </div>
                )}

                {currentCategory === 'bundle' && (
                  <>
                    <div className="socialMedia">
                      {icons.length > 0 && (
                        <>
                          {icons.map(item => (
                            <img
                              key={item}
                              src={getImage(item)}
                              alt="social-icon"
                            />
                          ))}
                        </>
                      )}
                    </div>

                    <div className="amount__bundle">
                      {pack.currency}
                      {pack.amount}
                    </div>
                    <div className="description">
                      {currentCategory === 'bundle' && pack.mainDescription
                        ? pack.mainDescription
                        : ' '}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {packSig && (
            <div
              className="claro__recommended__package__min"
              onClick={() => handleNextProduct('next')}
            >
              <div
                className={`package__box__title ${
                  currentCategory === 'topup'
                    ? 'package__box__title__topup'
                    : 'package__box__title__bundle'
                }`}
              >
                <p>
                  {packSig.title}
                  {currentCategory === 'bundle' && (
                    <>{`${packSig.subtitle} - ${packSig.validity}`}</>
                  )}
                </p>
              </div>
              <div
                className={`package__box__content${
                  currentCategory === 'topup' ? '__topup' : '__bundle'
                }`}
              >
                {currentCategory === 'topup' && (
                  <div className="amount__topup">
                    {packSig.currency}
                    {packSig.amount}
                  </div>
                )}
                {currentCategory === 'bundle' && (
                  <>
                    <div className="socialMedia">
                      {iconsSig.length > 0 &&
                        iconsSig.map(item => (
                          <img
                            key={item}
                            src={getImage(item)}
                            alt="social-icon"
                          />
                        ))}
                    </div>
                    <div className="amount__bundle">
                      {packSig.currency}
                      {packSig.amount}
                    </div>
                    <div className="description">
                      {packSig.mainDescription || ' '}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {packFinal && (
            <div className="claro__recommended__package__min">
              <div
                className={`package__box__title ${
                  currentCategory === 'topup'
                    ? 'package__box__title__topup'
                    : 'package__box__title__bundle'
                }`}
              >
                <p>
                  {packFinal.title}
                  {currentCategory === 'bundle' && (
                    <>{`${packFinal.subtitle} - ${packFinal.validity}`}</>
                  )}
                </p>
              </div>
              <div
                className={`package__box__content${
                  currentCategory === 'topup' ? '__topup' : '__bundle'
                }`}
              >
                {currentCategory === 'topup' && (
                  <div className="amount__topup">
                    {packFinal.currency}
                    {packFinal.amount}
                  </div>
                )}
                {currentCategory === 'bundle' && (
                  <>
                    <div className="socialMedia">
                      {iconsFinal.length > 0 &&
                        iconsFinal.map(item => (
                          <img
                            key={item}
                            src={getImage(item)}
                            alt="social-icon"
                          />
                        ))}
                    </div>
                    <div className="amount__bundle">
                      {packFinal.currency}
                      {packFinal.amount}
                    </div>
                    <div className="description">
                      {packFinal.mainDescription || ' '}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <CarouselControls
          currentCategory={currentCategory}
          bundle={bundle}
          topup={topup}
          currentPosition={currentPosition}
          handleNextProduct={handleNextProduct}
        />
      </div>

      <PaymentActionsNext
        textBtn={'Continuar'}
        onClick={handleSelectedProduct}
        enabled={true}
      />
    </>
  )
}

export default CarouselPackages
