import React from 'react'

const TyC_CR = () => {
  return (
    <>
      <p>
        CLARO CR TELECOMUNICACIONES S.A. (en adelante CLARO COSTA RICA) ponen a
        disposición de sus usuarios la opción de realizar compras de recargas y
        paquetes (“Servicios”) utilizando como medio de pago una tarjeta de
        crédito o débito, ya sea por evento o de forma recurrente mediante
        cobros automáticos, para lo cual deberá aceptarlos en las secciones
        dentro de los canales apropiados para tal fin.
      </p>
      <br />
      <p>
        La plataforma que le permite obtener los beneficios de realizar sus
        pagos de forma recurrente, mediante cobros automáticos, para la compra
        de paquetes y recargas con tarjeta de crédito o débito y que CLARO Costa
        Rica pone a su disposición, es propiedad de fonYou Telecom, S.L. (en
        adelante fonYou), por lo tanto el presente Aviso Legal (en adelante, el
        “Aviso Legal”) establece los Términos y Condiciones respecto al uso de
        dicha plataforma, a la afiliación de su tarjeta de crédito o débito y al
        pago recurrente mediante cobros automáticos para la compra de sus
        paquetes y recargas. Mediante su acceso y uso de los “Servicios”, al
        aceptar el USUARIO estos Términos y Condiciones, se le habilitarán las
        opciones para compra de paquetes y recargas por medio de tarjeta de
        crédito o débito CLARO COSTA RICA podrá poner fin de inmediato a estas
        Condiciones o cualquiera de los “Servicios” respecto de el USUARIO o, en
        general, dejar de ofrecer o denegar el acceso a los “Servicios” o
        cualquier parte de ellos, cuando la plataforma detecte un comportamiento
        inusual.
      </p>
      <br />
      <p>
        La navegación por las páginas de información y confirmación (en
        adelante, “Las Páginas”) le atribuye a usted la condición de USUARIO en
        “Las Páginas” e implica el conocimiento pleno y sin reservas de todas y
        cada una de las disposiciones incluidas en este Aviso Legal publicado
        por CLARO COSTA RICA En consecuencia, el USUARIO debe leer el presente
        Aviso Legal en cada una de las ocasiones en que navegue por el sitio Web
        y/o utilice “Las Páginas”, ya que puede sufrir modificaciones.
      </p>
      <br />
      <p>
        El USUARIO al “marcar casilla” confirma que ha leído, entendido y
        aceptado los Términos y Condiciones aplicables para hacer uso de las
        opciones que se le presentan para el pago al adquirir Paquetes y
        Recargas, ya sea con pago único por evento o con la afiliación de su
        tarjeta de crédito o débito para compras recurrentes mediante cobros
        automáticos de Paquetes y Recargas.
      </p>
      <br />
      <p>
        CLARO COSTA RICA puede modificar el presente Aviso Legal e informará a
        sus clientes las nuevas disposiciones a través de su sitio Web Dichas
        modificaciones serán efectivas a partir del momento en que queden
        disponibles al público y se haya dado aviso de sus cambios El USUARIO se
        considera enterado y obligado por los cambios al Aviso Legal desde el
        momento en que ingrese al sitio Web o a “Las Páginas”, El uso o acceso
        al sitio Web o a “Las Páginas” por parte del USUARIO constituirá el
        entendimiento, aceptación y acuerdo a los cambios del Aviso Legal.
      </p>
      <br />
      <p>
        Con la finalidad de realizar alguna actividad para proteger la seguridad
        del sitio Web o “Las Páginas”, o de las tarjetas de crédito y/o débito,
        o en caso de sospecha de cualquier tipo de fraude o anomalía, CLARO
        Costa Rica podrá en cualquier momento negar, suspender o dar por
        terminado el acceso o uso por parte del USUARIO al sitio Web o a “Las
        Páginas”, en cuyo caso el USUARIO recibirá un mensaje de texto (SMS) en
        su teléfono celular y/o un correo electrónico a la dirección
        proporcionada.
      </p>
      <br />
      <h3>LÍMITE DE RESPONSABILIDAD:</h3>
      <p>
        fonYou no es un proveedor de servicios de telefonía móvil, es únicamente
        el proveedor de la plataforma para pago de “Servicios” por medios
        electrónicos utilizando tarjetas de crédito y débito. fonYou no
        garantiza o se hace responsable de los “Servicios” ofrecidos por CLARO
        Costa Rica. Cualquier reclamo referente a la calidad, cantidad de
        minutos, costo de los “Servicios”, expiración de plazos u otras
        condiciones relativas a los “Servicios” contratados deben revisarse y
        solventarse entre el USUARIO y CLARO COSTA RICA.
      </p>
      <br />
      <p>
        fonYou respetará escrupulosamente la confidencialidad de los datos de
        carácter personal aportados por los USUARIOS de “Las Páginas”, mediante
        la observancia de su aviso de privacidad y de la normativa aplicable, de
        tal manera que fonYou no entregará información personal del USUARIO a
        terceros, a menos que exista un requerimiento judicial o bien que el
        mismo USUARIO lo requiera.
      </p>
      <br />
      <p>
        fonYou está comprometida y obligada a aplicar en su plataforma las más
        estrictas normas de seguridad para que los USUARIOS realicen el pago de
        “Servicios” por medios electrónicos utilizando tarjetas de crédito y
        débito, por tanto reconoce que CLARO COSTA RICA no asume ante El
        USUARIO, y éste no acepta ninguna responsabilidad ni otorga ninguna
        garantía con respecto al uso y seguridad de la plataforma.
      </p>
      <br />
      <p>
        Mediante el uso del sitio Web y/o “Las Páginas”, el USUARIO reconoce y
        acepta que CLARO COSTA RICA, sus subsidiarias y/o afiliadas quedan
        excluidas de cualquier responsabilidad por los daños y perjuicios que el
        USUARIO pudiera experimentar a causa de la falta de veracidad de la
        información proporcionada, o a causa de información que el USUARIO haya
        proporcionado o pueda ser conocida por terceros, relacionada a la compra
        por medios electrónicos usando la plataforma de fonYou y que sea
        utilizada, de cualquier forma, por tales terceros. Tanto el acceso al
        sitio Web o a “Las Páginas” como el uso que pueda hacerse de la
        información contenida en los mismos son exclusiva responsabilidad del
        USUARIO.
      </p>
      <br />
      <h3>MÉTODOS DE PAGO:</h3>
      <br />
      <p>
        La plataforma de fonYou está habilitada para procesar pagos a través de
        medios electrónicos mediante el uso de tarjetas de débito o de crédito,
        ya sea que el USUARIO efectúe pagos únicos o por afiliación al cobro
        recurrente de forma automática. La información proporcionada por el
        USUARIO será tratada y protegida como información confidencial y será
        utilizada única y exclusivamente para realizar el objetivo principal del
        USUARIO, que es comprar los paquetes y recargas para números de CLARO
        Costa Rica, pagando por medio de cualquiera de las opciones indicadas.
        Serán tarjetas aceptadas por la plataforma, las tarjetas de crédito o
        débito, nacionales, de marca VISA, MasterCard y American Express.
      </p>
      <br />
      <h3>SERVICIO DE COBRO RECURRENTE AUTOMÁTICO:</h3>
      <br />
      <p>
        El USUARIO tiene la opción de seleccionar de forma voluntaria la
        activación del cobro recurrente mediante cargos automáticos a su tarjeta
        de crédito o débito para la compra de paquetes y recargas para números
        CLARO COSTA RICA, por lo que a partir del momento en que el USUARIO
        selecciona dicha opción de pago, autoriza a CLARO COSTA RICA para
        efectuarle el cobro automático de los servicios seleccionados en las
        diferentes casillas de selección.{' '}
      </p>
      <br />
      <p>
        En caso de que el USUARIO haya presentado reclamo a CLARO COSTA RICA por
        el valor total o parcial de la compra de paquete o recarga, y no desease
        realizar el pago de este, deberá cancelar la afiliación, a fin de evitar
        que se realice un nuevo cobro.
      </p>
      <br />
      <p>
        El USUARIO recibirá las notificaciones vía SMS en el número telefónico
        afiliado.
      </p>
      <br />
      <h3>CANCELACIÓN DE LA AFILIACIÓN A COBRO RECURRENTE AUTOMÁTICO:</h3>
      <br />
      <p>
        El USUARIO podrá cancelar de forma voluntaria la afiliación para el
        cobro automático recurrente de compra de paquetes y/o recargas en
        cualquier momento de la siguiente forma:
      </p>
      <br />
      <p>
        Contactando al servicio al cliente de CLARO COSTA RICA al número
        telefónico 147-100 y el asesor de servicio al cliente enviará al USUARIO
        la liga para que éste cancele personalmente su afiliación al cobro
        automático recurrente.
      </p>
      <h3>CONDICIONES DE USO DEL SERVICIO:</h3>
      <p>
        El USUARIO reconoce y acepta que al utilizar SAT to Pay, es su voluntad
        que la compra de paquetes y/o recargas, según haya seleccionado, le sean
        cobrados por medios electrónicos, ya sea que efectúe un pago único o que
        haya optado por la afiliación de cobros recurrentes con cargos
        automáticos.
      </p>
      <br />
      <p>
        Si al momento en que CLARO COSTA RICA procese el cobro existe o se
        presenta una falla técnica que no permita reflejarlo correctamente, el
        USUARIO puede demostrar que el cobro fue aplicado en la tarjeta de
        crédito o débito utilizada, presentando el estado de cuenta del banco,
        en cuyo caso CLARO COSTA RICA tomará como válido el pago, aplicándolo a
        su compra y entregando el “Servicio” solicitado.
      </p>
      <br />
      <h3>PROPIEDAD INTELECTUAL:</h3>
      <p>
        Mediante el uso del sitio Web o “Las Páginas”, el USUARIO reconoce y
        acepta que CLARO COSTA RICA, sus subsidiarias y/o afiliadas, quedan
        excluidas de cualquier responsabilidad que pudiera ser causada por el
        uso no autorizado de las marcas u otros derechos de propiedad
        intelectual de terceros. De igual manera, las eventuales referencias que
        se hagan en el sitio Web o “Las Páginas” a cualquier producto, servicio,
        proceso, sitio enlazado, hipertexto o cualquier otra información en la
        que se utilicen marcas, signos distintivos y/o dominios, el nombre
        comercial o el nombre del fabricante, suministrador, etc., que sean
        titularidad de terceros, en ningún momento constituirán, ni implicarán
        respaldo o recomendación alguna por parte de CLARO COSTA RICA, sus
        subsidiarias y/o afiliadas y en ningún caso CLARO COSTA RICA, sus
        subsidiarias y/o afiliadas se asignan propiedad ni responsabilidad sobre
        los mismos.
      </p>
      <br />
      <p>
        El USUARIO reconoce y acepta que el uso del sitio Web o “Las Páginas” no
        le confiere ningún acceso y/o derecho de uso adicional respecto de los
        “Servicios” de CLARO COSTA RICA en su dispositivo personal, ni tampoco
        sobre cualquier contenido, información y material relacionado que pueda
        estar o encontrar disponible a través de los “Servicios”, reconociendo
        también y obligándose a que dicha información no la utilizará para fines
        comerciales sino únicamente personales, y asumiendo las
        responsabilidades legales aplicables, en caso de infracciones a lo
        anterior.
      </p>
      <br />
      <p>
        El USUARIO reconoce también que aunque haga uso del sitio Web o “Las
        Páginas” no podrá: (i) retirar cualquier nota de derechos de autor,
        marca registrada u otra nota de propiedad de cualquier parte de los
        “Servicios”; (ii) reproducir, modificar, preparar obras derivadas sobre
        los “Servicios”, distribuir, licenciar, arrendar, revender, transferir,
        exhibir públicamente, presentar públicamente, transmitir, retransmitir o
        explotar de otra forma los “Servicios”, excepto como se permita
        expresamente por fonYou y/o CLARO COSTA RICA; (iii) descompilar,
        realizar ingeniería inversa o desmontar los “Servicios”, excepto como se
        permita por la ley aplicable; (iv) enlazar, reflejar o enmarcar
        cualquier parte de los “Servicios”; (v) causar o lanzar cualquier
        programa o script con el objeto de extraer, indexar, analizar o de otro
        modo realizar prospección de datos de cualquier parte de los “Servicios”
        o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de
        cualquier aspecto de los “Servicios”; o (vi) intentar obtener un acceso
        no autorizado o dañar cualquier aspecto de los “Servicios” o sus
        sistemas o redes relacionados. En caso de infracciones a lo anterior, el
        USUARIO asume las responsabilidades legales aplicables.
      </p>
      <br />
      <p>
        Los “Servicios” y todos los derechos relativos a estos son y
        permanecerán de la propiedad de CLARO COSTA RICA y fonYou o de sus
        licenciantes, según aplique. El uso del sitio Web, de “Las Páginas” y/o
        de los “Servicios” de CLARO no le transfieren u otorgan ningún derecho
        adicional sobre o en relación con los “Servicios”, ni a comercializarlos
        de ninguna manera; y tampoco a utilizar o mencionar de cualquier modo
        los nombres de empresas, nombres comerciales, logotipos, diseños,
        nombres de productos y/o servicios, marcas comerciales o marcas de
        servicio de CLARO o de sus licenciantes.
      </p>
      <br />
      <h3>INFORMACIÓN PROPORCIONADA POR EL USUARIO</h3>
      <p>
        CLARO COSTA RICA y/o fonYou podrán solicitarle a El USUARIO, cuando
        consideren oportuno, que envíe, cargue, publique o de otro modo ponga a
        su disposición, a través de los “Servicios”, contenido e información de
        texto, audio y/o visual, incluidos comentarios y opiniones relativos a
        los “Servicios”, iniciación de peticiones de apoyo, así como
        presentación de admisiones para competiciones y promociones (“Contenido
        de Usuario”). Todo “Contenido de Usuario” facilitado por El USUARIO
        seguirá siendo de su propiedad. No obstante, al proporcionar “Contenido
        de Usuario” a la plataforma de fonYou, el USUARIO otorga a CLARO Costa
        Rica y a fonYou una licencia mundial, perpetua, irrevocable,
        transferible, libre de regalías, con derecho a sublicenciar, usar,
        copiar, modificar, crear obras derivadas, distribuir, exhibir
        públicamente, presentar públicamente o de otro modo explotar de
        cualquier manera dicho “Contenido de Usuario” en todos los formatos y
        canales de distribución, conocidos ahora o ideados en un futuro
        (incluidos en relación con los “Servicios” y el negocio de CLARO Costa
        Rica y fonYou y en sitios y servicios de terceros), sin más aviso o
        consentimiento de el USUARIO y sin requerirse el pago a el USUARIO o a
        cualquier otra persona o entidad.
      </p>
      <br />
      <p>
        En los casos que el USUARIO acceda y facilite a CLARO COSTA RICA y/o a
        fonYou su “Contenido de Usuario”, declara y garantiza que: (i) es el
        único y exclusivo propietario de toda la Información y el “Contenido de
        Usuario” o que tiene todos los derechos, licencias, consentimientos y
        permisos necesarios para otorgar a CLARO COSTA RICA y/o a fonYou la
        licencia al “Contenido de Usuario” como se ha establecido anteriormente;
        y (ii) ni el “Contenido de Usuario” ni su presentación, carga,
        publicación o puesta a disposición por cualquier otro medio o forma, ni
        el uso por parte de CLARO COSTA RICA y/o fonYou de dicho “Contenido de
        Usuario”, como está permitido en estos términos y condiciones,
        infringen, malversan o violan la propiedad intelectual o los derechos de
        propiedad de un tercero o los derechos de publicidad o privacidad, o
        resultan en la violación de cualquier ley o reglamento aplicable.
      </p>
      <br />
      <p>
        El USUARIO se obliga a no proporcionar “Contenido de Usuario” que sea
        difamatorio, calumnioso, denigrante, odioso, violento, obsceno,
        pornográfico, ilícito o de otro modo ofensivo, según determine CLARO
        Costa Rica a su sola discreción, ya sea que dicho material pueda estar
        protegido o no por la ley. En esos casos, CLARO COSTA RICA podrá, a su
        sola discreción y en cualquier momento y por cualquier motivo, sin
        avisarle previamente, revisar, controlar o eliminar “Contenido de
        Usuario”, pero sin estar obligada a ello.
      </p>
      <br />
      <h3>DISPOSITIVOS:</h3>
      <p>
        El USUARIO es responsable de adquirir y actualizar el hardware
        compatible o los dispositivos necesarios para acceder y utilizar los
        “Servicios” y Aplicaciones y cualquier actualización de estos, lo
        anterior con la finalidad de que el o los dispositivos que utilice el
        USUARIO para ingresar al Sitio Web o “Las Páginas” sean compatibles con
        la plataforma. Por lo anterior, CLARO COSTA RICA y fonYou no garantizan
        que los “Servicios”, o cualquier parte de estos, funcionen en cualquier
        hardware o dispositivo particular. Además, los “Servicios” podrán ser
        objeto de disfunciones o retrasos en función de la capacidad del
        Internet y de las comunicaciones electrónicas que utilice el USUARIO.{' '}
      </p>
      <br />
      <h3>MENSAJES DE TEXTO</h3>
      <p>
        Al usar los medios de pago con tarjeta de crédito o débito, ya sea por
        evento o de forma recurrente mediante cobros automáticos, el USUARIO
        acepta que se le puedan enviar mensajes de texto informativos (SMS) como
        parte de la actividad comercial normal por el uso de los “Servicios” de
        CLARO COSTA RICA. El USUARIO podrá solicitar la no recepción de mensajes
        de texto informativos (SMS) de la plataforma en cualquier momento
        llamando al 147-100 indicando que no desea recibir más dichos mensajes,
        junto con el número de teléfono del dispositivo móvil en el que recibe
        los mensajes. El USUARIO reconoce que solicitar la no recepción de
        mensajes de texto informativos (SMS) podrá afectar el uso que El USUARIO
        haga de los “Servicios”, en el sentido de que no recibirá, por ejemplo,
        los avisos de vencimiento o similares.
      </p>
      <br />
      <h3>PAGO</h3>
      <p>
        El USUARIO entiende que el uso de los “Servicios” puede derivar en
        cargos por los servicios o bienes que reciba de un tercer proveedor
        incluyendo cargos por navegación, inscripción, alta o similares
        (“Cargos”). Después de que haya recibido los “Servicios” u obtenido los
        bienes mediante el uso de los “Servicios”, CLARO COSTA RICA facilitará
        el pago de los “Cargos” aplicables en nombre del tercero proveedor como
        agente de cobro limitado del tercero proveedor. El pago de los “Cargos”
        de dicha manera se considerará como el pago efectuado directamente por
        el USUARIO al tercero proveedor. Los “Cargos” incluirán los impuestos
        aplicables cuando se requiera por ley. Los “Cargos” pagados por el
        USUARIO son definitivos y no reembolsables, a menos que CLARO COSTA RICA
        y fonYou determinen lo contrario.{' '}
      </p>
      <br />
      <p>
        Todos los Cargos son pagaderos inmediatamente y el pago se facilitará
        por fonYou utilizando al método de pago indicado por el USUARIO o
        utilizado anteriormente. Si se determina que el método de pago de el
        USUARIO ha caducado, es inválido o de otro modo no sirve para cobrarle,
        el USUARIO acepta que fonYou, como agente de cobro limitado del tercero
        proveedor, utilice un método de pago secundario en su cuenta, si
        estuviera disponible.
      </p>
      <br />
      <p>
        El USUARIO acepta expresamente que fonYou recuerde en su plataforma los
        medios de pago utilizados por el USUARIO para su futura utilización en
        la plataforma al momento de realizar el pago por la compra de recargas y
        paquetes.
      </p>
      <br />
      <p>
        CLARO COSTA RICA, en cualquier momento y a su sola discreción, se
        reserva el derecho de establecer, eliminar y/o revisar los precios de
        los paquetes y recargas para alguno o todos los servicios o bienes
        obtenidos a través del uso de los “Servicios”. CLARO COSTA RICA usará
        los esfuerzos razonables para informar al USUARIO de los Cargos que
        podrán aplicarse por la compra de paquetes y recargas, siendo El USUARIO
        responsable de los “Cargos” incurridos en su cuenta, independientemente
        de que el USUARIO conozca dichos “Cargos” o los importes de estos. CLARO
        COSTA RICA podrá, cuando lo considere oportuno, proporcionar a
        determinados USUARIOS ofertas promocionales y descuentos que pueden
        resultar en el cobro de diferentes importes por estos o similares
        servicios o bienes obtenidos a través del uso de los “Servicios”, y el
        USUARIO acepta que dichas ofertas promocionales y descuentos, a menos
        que también se pongan a su disposición, no se tendrán en cuenta en el
        uso por su parte de los “Servicios” o los Cargos aplicados a el USUARIO.
        El USUARIO podrá, en cualquier momento, optar por cancelar su solicitud
        para los servicios o bienes que adquirió de un tercero proveedor.
      </p>
      <br />
      <h3>RENUNCIA.</h3>
      <p>
        Los “Servicios” se proporcionan “Tal Cual” y “Como disponibles”. Debido
        a que los “Servicios” son autogestionados, el USUARIO exime a CLARO
        COSTA RICA y a fonYou de toda responsabilidad relacionada con el proceso
        de compra de los “Servicios”, reconociendo que CLARO COSTA RICA y/o
        fonYou no pueden garantizar que el USUARIO ejecute correctamente los
        pasos para adquirir los “Servicios” o que en dicho proceso no surjan
        fallas de cualquier tipo. Asimismo, el USUARIO reconoce que el uso de
        esta plataforma para la adquisición de los “Servicios” no traslada a
        CLARO COSTA RICA y fonYou la responsabilidad de garantizar la
        fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los
        “Servicios” o cualquier servicio o bien solicitado a través del uso de
        los “Servicios”, o que los “Servicios” no serán interrumpidos o estarán
        libres de errores. CLARO COSTA RICA y fonYou no garantizan la calidad,
        idoneidad, seguridad o habilidad de los “Servicios” que el USUARIO
        adquiera de terceros proveedores, por tanto, el USUARIO declara que todo
        riesgo derivado del uso de dichos “Servicios” y cualquier servicio o
        bien solicitado por él de un tercero proveedor será de su exclusiva
        responsabilidad, en la máxima medida permitida por la Ley aplicable.
      </p>
      <br />
      <p>
        Las limitaciones y la renuncia en este apartado no pretenden limitar la
        responsabilidad o alterar sus derechos como consumidor que no puedan
        excluirse según la Ley Aplicable.
      </p>
      <br />
      <h3>ACLARACIONES DEL USUARIO:</h3>
      <p>
        El USUARIO podrá aclarar cualquier duda relacionada con las compras de
        sus paquetes o recargas cuyo pago efectuaron con tarjeta de crédito o
        débito, ya sea por evento o de forma recurrente mediante cobros
        automáticos, contactando al servicio al cliente de CLARO COSTA RICA al
        correo: clientes@claro.cr.
      </p>
      <br />
      <h3>LEY APLICABLE Y JURISDICCIÓN:</h3>
      <p>
        El USUARIO, al hacer uso de cualquiera de las formas de pago que CLARO
        Costa Rica le ofrece por medio de la plataforma de fonYou para la compra
        de paquetes y recargas de CLARO COSTA RICA, acepta de manera expresa,
        que todas las controversias que surjan por el uso de dichas formas de
        pago se resolverán amigablemente entre las Partes, y en caso de no
        existir arreglo, las controversias se dilucidarán ante la jurisdicción
        de los tribunales de la ciudad de Costa Rica, en la vía sumaria
        renunciando las Partes a cualquier otra jurisdicción que les competa por
        cualquier otra razón.
      </p>
    </>
  )
}

export default TyC_CR
