export function generarUuid() {
  const crypto = window.crypto
  const array = new Uint8Array(16)
  crypto.getRandomValues(array)

  // Manipular los bytes según la especificación del UUID versión 4
  array[6] = (array[6] & 0x0f) | 0x40 // Establecer la versión a 4
  array[8] = (array[8] & 0x3f) | 0x80 // Establecer el variante a RFC 4122

  // Convertir a formato de cadena UUID
  const hex = Array.from(array)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')
  return [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20),
  ].join('-')
}
