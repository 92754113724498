import React, { useContext, useEffect, useState } from 'react'

import { Redirect, useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import PaymentActionsPayment from '../../components/PaymentActionsPayment'
import { generarUuid } from '../../components/GenerarUuid'
import RecommendedPackage from '../../components/RecommendedPackage'
import MoreOptions from '../../components/MoreOptions'

import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'
import { PRODUCT_SELECTED_HOME } from '../../constants/ClaroPayConstants'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentAccountBi from '../ClaroPayPaymentAccountBi'

const ClaroHome = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const {
    isLoading,
    homeProduct,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    isVisibleSectionAddNewCard,
    isEnabledPayWithNewCard,
    isEnabledPayWithSavedCard,
    setResponseService,
    setIsVisibleAddNewPaymentMethod,
    setIsVisibleAddNewAccountBi,
    payWithAccountBi,
    payWithNewAccountBi,
    setIsVisibleSectionAddNewCard,
    paymentCard,
    setProcessingPayment,
    sendMetrics,
    canClickButton,
    setCanClickButton,
    setOpcionSeleccionada,
    indicator,
    setIsLoading,
    setHomeProduct,
    getCard,
  } = useContext(ClaroPayContext)

  const [redirect, setRedirect] = useState(false)

  const validationProductSelected = async () => {
    const productSelectedConExpiracion = JSON.parse(
      getLocalStorage(PRODUCT_SELECTED_HOME),
    )
    if (productSelectedConExpiracion) {
      const ahora = new Date().getTime()
      if (ahora > productSelectedConExpiracion.expiracion) {
        clearLocalStorage(PRODUCT_SELECTED_HOME)
        setIsLoading(false)
        setRedirect(true)
      } else {
        setHomeProduct(productSelectedConExpiracion.product)
        await getCard()
        if (productSelectedConExpiracion.product.productType === 'TOPUP') {
          setOpcionSeleccionada('Recargas')
        } else {
          setOpcionSeleccionada('Superpacks')
        }
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
      setRedirect(true)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    validationProductSelected()
  }, [])

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    history.push(claroPayPaymentProcess)
    const response = await paymentCard(uuid)
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBi(uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(homeProduct.productId)
  }

  const handleSelectedProduct = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'MasOpciones',
    }
    sendMetrics(metricsData)
    clearLocalStorage(PRODUCT_SELECTED_HOME)
    setHomeProduct({})
    setRedirect(true)
  }

  const textBtn = `Pagar ${homeProduct.currency}${homeProduct.amount}`
  const currentCategory =
    homeProduct.productType === 'TOPUP' ? 'topup' : 'bundle'

  return (
    <>
      {redirect && <Redirect to="/home" />}

      {!isLoading && homeProduct && Object.keys(homeProduct).length > 0 && (
        <section className="claro__container__home">
          <RecommendedPackage
            pack={homeProduct}
            currentCategory={currentCategory}
          />

          <MoreOptions handleSelectedProduct={handleSelectedProduct} />

          <div className="claro__payment__methods">
            {countryCode === '507' ? (
              <ClaroPayPaymentAccountBi />
            ) : (
              <ClaroPayPaymentOptions />
            )}
          </div>

          <PaymentActionsPayment
            selectAccountBi={selectAccountBi}
            iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
            indicator={indicator}
            countryCode={countryCode}
            textBtn={textBtn}
            handlePaymentNewAccountBi={handlePaymentNewAccountBi}
            handlePaymentAccountBi={handlePaymentAccountBi}
            canClickButton={canClickButton}
            handlePayment={handlePayment}
            isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
            isEnabledPayWithNewCard={isEnabledPayWithNewCard}
            isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
          />
        </section>
      )}
    </>
  )
}

export default ClaroHome
