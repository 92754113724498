import { useState, useEffect } from 'react'

const DOMAIN_TO_COUNTRY_CODE_MAP = {
  'recargo.miclaro.com.gt': '502',
  'recargo.miclaro.com.sv': '503',
  'recargo.miclaro.com.hn': '504',
  'recargo.miclaro.com.ni': '505',
  'recargo.miclaro.cr': '506',
  'dev-gt.recargomiclaro.com': '502',
  'dev-sv.recargomiclaro.com': '503',
  'dev-hn.recargomiclaro.com': '504',
  'dev-ni.recargomiclaro.com': '505',
  'dev-cr.recargomiclaro.com': '506',
  'localhost:4004': '502',
}

function Country() {
  const [domain, setDomain] = useState(getDomain())
  const countryCode = DOMAIN_TO_COUNTRY_CODE_MAP[domain]

  useEffect(() => {
    setDomain(getDomain())
  }, [])

  function getDomain() {
    let host = window.location.host
    host = host.replace('www.', '')
    return host
  }

  return { domain, countryCode }
}

export default Country
