import React from 'react'

import { labels } from '../../constants/ClaroPayConstants'

import claroIcon from '../../assets/img/claroIcon.png'

const Header = ({ mssidn, toggleMenu }) => {
  const { GREETINGS } = labels

  return (
    <div className="claro__layout__header">
      <div className="claro__layout__header__primary">
        <p className="claro__layout__header__primary__greetings">{GREETINGS}</p>
        <p className="claro__layout__header__primary__msisdn">
          {mssidn ?? mssidn}
        </p>
      </div>

      <div className="claro__layout__header__secondary">
        <div
          role="button"
          className="claro__layout__header__secondary__menuVIcon"
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <div className="claro__layout__header__secondary__menuVIcon__line"></div>
          <div className="claro__layout__header__secondary__menuVIcon__line"></div>
          <div className="claro__layout__header__secondary__menuVIcon__line"></div>
        </div>
        <a href="/home" className="claro__layout__header__secondary__claroIcon">
          <img src={claroIcon} alt="claroIcon" />
        </a>
      </div>
    </div>
  )
}

export default Header
