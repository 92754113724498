import React from 'react'

import { getImage } from '../GetImage'

const RecommendedPackage = ({ pack, currentCategory }) => {
  const icons = pack?.socialMedia?.[0]?.icons || []

  return (
    <div className="claro__recommended__package">
      <div
        className={`package__box__title ${
          currentCategory === 'topup'
            ? 'package__box__title__topup'
            : 'package__box__title__bundle'
        }`}
      >
        <p>
          {pack.title}
          {currentCategory === 'bundle' && (
            <>
              <br />
              {`${pack.subtitle} - ${pack.validity}`}
            </>
          )}
        </p>
      </div>

      <div className="package__box__content">
        {currentCategory === 'topup' && (
          <div className="amount__topup">
            {pack.currency}
            {pack.amount}
          </div>
        )}

        {currentCategory === 'bundle' && (
          <>
            <div className="socialMedia">
              {icons.length > 0 && (
                <>
                  {icons.map(item => (
                    <img key={item} src={getImage(item)} alt="social-icon" />
                  ))}
                </>
              )}
            </div>
            <div className="amount__bundle">
              {pack.currency}
              {pack.amount}
            </div>
            <div className="description">{pack.mainDescription || ' '}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default RecommendedPackage
