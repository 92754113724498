import React, { useState } from 'react'

const CustomSwitch = ({ isSelected, onClick, text }) => {
  const [selected, setSelected] = useState(isSelected)

  return (
    <div className="remember__date">
      <div
        role="button"
        className={`custom__switch ${selected && 'custom__switch__selected'}`}
        onClick={() => {
          onClick(!selected)
          setSelected(!selected)
        }}
      >
        <span
          className={`circle__switch ${
            selected
              ? 'circle__switch__selected'
              : 'circle__switch__not__selected'
          }`}
        />
      </div>
      <div className="contentTC">
        <div className="descriptionTC">{text}</div>
      </div>
    </div>
  )
}
export default CustomSwitch
