import React, { useContext, useEffect, useState } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import CardInformation from '../../components/CardInformation'
import CustomSwitch from '../../components/CustomSwitch'
import {
  lastTwoDigitsYear,
  penultimateTwoDigitsYear,
} from '../../components/DateYear'
import { nextInput } from '../../components/NextInput'

import {
  labels,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'
import logoBi from '../../assets/img/Bi-Logotipo-Azul.png'

const ClaroPayPaymentAccountBi = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    CARD,
    NEWPAYMENTMETHOD,
    BANK_ACCOUNT,
    VIEW_OTHER_PAYMENT_OPTIONS,
    PAYMENT_TITLE,
    CUENTA_BI,
  } = labels

  const {
    recurrenceData,
    selectedSavedCard,
    setSelectedSavedCard,
    saveCard,
    setSaveCard,
    setYear,
    setMonth,
    saveNewCard,
    setSaveNewCard,
    yearNewCard,
    setYearNewCard,
    monthNewCard,
    setMonthNewCard,
    cvv,
    setCvv,
    numberCard,
    setNumberCard,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    maxLengthCvv,
    setMaxLengthCvv,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    callMeData,
    bnplData,
    walletRecoveryData,
    minecoBecasData,
    cbkUssdData,
    homeData,
    sendARechargeData,
    sendMetrics,
    code,
    setProcessingPayment,
    processingPayment,
    setResponseService,
    responseService,
    setCanClickButton,
    demoBiData,
    setCodeBilling,
  } = claroPayContext

  useEffect(() => {
    setProcessingPayment(false)
    setResponseService(null)
    clearLocalStorage(METRICS_SESSION)
    clearLocalStorage(PAYMENT_PROCESS)
    setCodeBilling(null)
    const procesoPago = getLocalStorage(PROCESO_PAGO)
    if (procesoPago) {
      setCanClickButton(false)
      const timeoutId = setTimeout(() => {
        setCanClickButton(true)
        clearLocalStorage(PROCESO_PAGO)
      }, 30000)

      return () => clearTimeout(timeoutId)
    } else {
      setCanClickButton(true)
    }
  }, [responseService, processingPayment])

  let creditCardList = []
  let alias = []
  let indicator = false

  const dataSources = [
    bnplData,
    walletRecoveryData,
    minecoBecasData,
    cbkUssdData,
    callMeData,
    recurrenceData,
    sendARechargeData,
    demoBiData,
  ]

  for (const source of dataSources) {
    if (source?.creditCardList) {
      creditCardList = source.creditCardList
      break
    }
  }

  for (const source of dataSources) {
    if (source?.indicator) {
      indicator = source.indicator
      break
    }
  }

  for (const source of dataSources) {
    if (source?.alias) {
      alias = indicator ? source.alias ?? [] : []
      break
    }
  }

  if (homeData) {
    creditCardList = homeData.tokenList
    alias = homeData.alias
    indicator = homeData.indicator
  }

  if (creditCardList) {
    for (let i = 0; i < creditCardList.length; i++) {
      creditCardList[i].position = i + 1
    }
  }

  if (alias) {
    for (let i = 0; i < alias.length; i++) {
      alias[i].position = i + 1
    }
  }

  const [showAllAccounts, setShowAllAccounts] = useState(false)
  const combinedData = [...creditCardList, ...alias]
  const sortedData = combinedData.toSorted((a, b) => b.lastUse - a.lastUse)
  const visibleAccounts = showAllAccounts ? sortedData : sortedData.slice(0, 4)

  const isAnyDataPresent = () => {
    return (
      walletRecoveryData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  useEffect(() => {
    const metricsData = getMetricsData()
    if (metricsData) {
      if (isAnyDataPresent()) {
        handleSendMetrics(metricsData)
      }
    }
  }, [code])

  const getMetricsData = () => {
    if (creditCardList.length === 0 && alias.length === 0) {
      setIsVisibleAddNewPaymentMethod(true)
      if (!indicator) {
        setIsVisibleSectionAddNewCard(true)
        if (isAnyDataPresent()) {
          return {
            metricType: 'CheckOut_',
            metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
          }
        }
      } else {
        setIsVisibleAddNewAccountBi(true)
        if (isAnyDataPresent()) {
          return {
            metricType: 'CheckOut_',
            metricName: `NuevaCuenta-${alias.length + 1}`,
          }
        }
      }
    } else if (creditCardList.length === 1 && alias.length === 0) {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
      setSelectedSavedCard(creditCardList[0])
      if (isAnyDataPresent()) {
        return {
          metricType: 'CheckOut_',
          metricName: `PagarTC-${creditCardList.length}`,
        }
      }
    } else if (alias.length === 1 && indicator && creditCardList.length <= 1) {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
      setSelectAccountBi(alias[0].alias)
      if (isAnyDataPresent()) {
        return {
          metricType: 'CheckOut_',
          metricName: `PagarBanco-${alias.length}`,
        }
      }
    } else {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
    }
    return null
  }

  const handleSendMetrics = metricsData => {
    if (homeData || code) {
      sendMetrics(metricsData)
    }
  }

  const handleSwitchClick = () => {
    setSaveNewCard(prevState => {
      if (!prevState) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `SaveCard-${creditCardList.length + 1}`,
        }
        if (isAnyDataPresent()) {
          sendMetrics(metricsData)
        }
      }
      return !prevState
    })
  }

  const toggleShowAllAccounts = () => {
    if (!showAllAccounts && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'VerTusOpcionesdePago',
      }
      sendMetrics(metricsData)
    }
    setShowAllAccounts(!showAllAccounts)
  }

  const resert = () => {
    setSelectedSavedCard({})
    setSelectAccountBi(null)
    setMonth('')
    setYear('')
    setSaveCard(false)
    setMonthNewCard('')
    setYearNewCard('')
    setCvv('')
    setNumberCard('')
    setSaveNewCard(false)
  }

  const handleAddNewCard = () => {
    if (!isVisibleSectionAddNewCard && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleSectionAddNewCard(!isVisibleSectionAddNewCard)
    setIsVisibleAddNewAccountBi(false)
    resert()
  }

  const handleAddNewPaymentMethod = () => {
    if (!isVisibleAddNewPaymentMethod && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'MasOpcionesPago',
      }
      sendMetrics(metricsData)
    }
    setIsVisibleAddNewPaymentMethod(!isVisibleAddNewPaymentMethod)
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewAccountBi(false)
    resert()
  }

  const handleAddNewAccountBi = () => {
    if (!iIsVisibleAddNewAccountBi && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaCuenta-${alias.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleAddNewAccountBi(!iIsVisibleAddNewAccountBi)
    setIsVisibleSectionAddNewCard(false)
    resert()
  }

  const handleSelectAccountBi = accountBi => {
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewAccountBi(false)
    resert()
    if (selectAccountBi && selectAccountBi === accountBi.alias) {
      setSelectAccountBi(null)
    } else {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `PagarBanco-${accountBi.position}`,
        }
        sendMetrics(metricsData)
      }
      setSelectAccountBi(accountBi.alias)
    }
  }

  const handleChangeMonth = event => {
    if (event.target.value.length <= 2) {
      if (
        event.target.value === '' ||
        (Number(event.target.value) >= 0 && Number(event.target.value) < 13)
      ) {
        setMonthNewCard(event.target.value)
        if (event.target.value.length === 2) nextInput(event)
      }
    }
  }

  const handleChangeYear = event => {
    const inputValue = event.target.value
    if (inputValue.length <= 2) {
      const isValidYear =
        (yearNewCard === '' &&
          Number(inputValue) >= Number(penultimateTwoDigitsYear)) ||
        Number(inputValue) >= Number(lastTwoDigitsYear) ||
        (yearNewCard !== '' && yearNewCard.length === 2) ||
        (yearNewCard !== '' && inputValue === '')

      if (isValidYear) {
        setYearNewCard(inputValue)
      }

      if (inputValue.length === 2) {
        nextInput(event)
      }
    }
  }

  const handleChangeCvv = event => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }

    if (event.target.value.length <= maxLengthCvv) {
      setCvv(event.target.value)
    }
  }

  const handleChangeCardNumber = event => {
    if (event.target.value.length <= maxLengthNumberCard) {
      setNumberCard(event.target.value)
    }
    if (event.target.value.length === maxLengthNumberCard) {
      nextInput(event)
    }
  }

  useEffect(() => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }
  }, [numberCard])

  return (
    <div className="claro__container__payment__accountBi">
      <div>
        <div className="title__payment__accountBi">
          <p className="">{PAYMENT_TITLE}</p>
        </div>

        {visibleAccounts !== null && (
          <>
            {visibleAccounts.map(item => {
              if (item.tokenId) {
                const isVisible =
                  isVisibleSectionAddNewCard === true
                    ? false
                    : item.lastDigits === selectedSavedCard.lastDigits

                return (
                  <CardInformation
                    key={item.tokenId}
                    isSelected={saveCard}
                    onChangeMonth={setMonth}
                    onChangeYear={setYear}
                    onChangeSwitch={setSaveCard}
                    isVisible={isVisible}
                    creditCard={item}
                    titleCarCss="text__add_card mb-0"
                    sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
                  />
                )
              } else {
                const isSelected = selectAccountBi === item.alias
                return (
                  <div className="card__information_container" key={item.alias}>
                    <div
                      className="card__information row"
                      onClick={() => handleSelectAccountBi(item)}
                    >
                      <p className="col-10 d-flex align-items-center text__add_card mb-0">
                        <img
                          className="mr-2"
                          src={isSelected ? circleAccepted : circleNotAccepted}
                          alt={`${CUENTA_BI}: ${item.alias}`}
                        />
                        {`${CUENTA_BI}: ${
                          item.alias.length > 12
                            ? item.alias.slice(0, 12) + '...'
                            : item.alias
                        }`}
                      </p>
                      <p className="col-2 text-right">
                        <img
                          src={logoBi}
                          alt="logoBi"
                          style={{ height: '32px', width: '32px' }}
                        />
                      </p>
                    </div>
                  </div>
                )
              }
            })}
          </>
        )}

        {combinedData.length > 4 && showAllAccounts === false && (
          <p className="text__add_default" onClick={toggleShowAllAccounts}>
            <img
              className="mr-2"
              src={`${showAllAccounts ? circleAccepted : circleNotAccepted}`}
              alt={VIEW_OTHER_PAYMENT_OPTIONS}
            />
            {VIEW_OTHER_PAYMENT_OPTIONS}
          </p>
        )}

        {(creditCardList.length > 0 || alias.length > 0) &&
          (creditCardList.length < 3 || (alias.length < 3 && indicator)) && (
            <p
              className="text__add_default"
              onClick={handleAddNewPaymentMethod}
            >
              <img
                className="mr-2"
                src={`${
                  isVisibleAddNewPaymentMethod
                    ? circleAccepted
                    : circleNotAccepted
                }`}
                alt={NEWPAYMENTMETHOD}
              />
              {NEWPAYMENTMETHOD}
            </p>
          )}

        {alias.length < 3 && isVisibleAddNewPaymentMethod && indicator && (
          <div className="text__add_new_payment_method">
            <div
              className="card__information row"
              onClick={handleAddNewAccountBi}
            >
              <p className="col-10 d-flex align-items-center text__add_card mb-0">
                <img
                  className="mr-2"
                  src={
                    iIsVisibleAddNewAccountBi
                      ? circleAccepted
                      : circleNotAccepted
                  }
                  alt={BANK_ACCOUNT}
                />
                {BANK_ACCOUNT}
              </p>
              <p className="col-2 text-right">
                <img
                  src={logoBi}
                  alt="logoBi"
                  style={{ height: '22px', width: '25px' }}
                />
              </p>
            </div>
          </div>
        )}

        {creditCardList.length < 3 && isVisibleAddNewPaymentMethod && (
          <p
            className="text__add_new_payment_method"
            onClick={handleAddNewCard}
          >
            <img
              className="mr-2"
              src={`${
                isVisibleSectionAddNewCard ? circleAccepted : circleNotAccepted
              }`}
              alt={CARD}
            />
            {CARD}
          </p>
        )}

        {isVisibleSectionAddNewCard === true && (
          <form className="new__card__container mx-3">
            <div className="number__card">
              <p className="titles_info_card">Número de tarjeta</p>
              <input
                placeholder="Ingresa el número de tu tarjeta"
                maxLength="2"
                className="data_info_card"
                type="number"
                name="number_card"
                value={numberCard}
                autoFocus={true}
                onChange={handleChangeCardNumber}
              />
            </div>
            <div className="expiration__date">
              <p className="titles_info_card">MM/AA</p>
              <div className="month__and__year">
                <input
                  placeholder="00"
                  maxLength="2"
                  className=" data__expiration__date"
                  type="number"
                  name="month"
                  value={monthNewCard}
                  onChange={handleChangeMonth}
                />
                /
                <input
                  placeholder="00"
                  maxLength="2"
                  className=" data__expiration__date"
                  type="number"
                  name="year"
                  value={yearNewCard}
                  onChange={handleChangeYear}
                />
              </div>
            </div>
            <div className="cvv">
              <p className="titles_info_card">CVV</p>
              <input
                placeholder="000"
                maxLength="4"
                className="data_info_card"
                type="number"
                name="cvv"
                value={cvv}
                onChange={handleChangeCvv}
              />
            </div>
            <CustomSwitch
              isSelected={saveNewCard}
              onClick={handleSwitchClick}
              text={'Guardar datos de tarjeta, para futuras compras'}
            />
          </form>
        )}
      </div>
    </div>
  )
}

export default ClaroPayPaymentAccountBi
