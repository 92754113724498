import axios from 'axios'

let host = window.location.host

let urlBackend = `https://${host}/mobisite/v1/`

const axiosConfig = axios.create({
  baseURL: urlBackend,
  withCredentials: true,
})

export default axiosConfig
