export const CODE_RECURRENCE = 'CODE_RECURRENCE'
export const CODE_CALLED_ME = 'CODE_CALLED_ME'
export const CODE_BNPL = 'CODE_BNPL'
export const CODE_WALLET_RECOVERY = 'CODE_WALLET_RECOVERY'
export const CODE_CBK_USSD = 'CODE_CBK_USSD'
export const CODE_BILLING = 'CODE_BILLING'
export const CODE_ADM = 'CODE_ADM'
export const CODE_SESSIONBI = 'CODE_SESSIONBI'
export const CODE_DELETEACCOUNTBI = 'CODE_DELETEACCOUNTBI'
export const CODE_SEND_A_RECHARGE = 'CODE_SEND_A_RECHARGE'
export const UUID_PROCESS_ADM = 'processAdm'
export const MSISDN_ADM = 'msisdnAdm'
export const AUTH_TOKENS_ADM = 'authTokensAdm'
export const PRODUCT_AUTH_ADM = 'productAuthAdm'
export const CODE_DEMOBI = 'CODE_DEMOBI'
export const PROCESO_PAGO = 'proceso-pago'
export const METRICS_SESSION = 'metricSesion'
export const PRODUCT_SELECTED_HOME = 'productSelected'
export const MENU_REDIRECT = 'menu-redirect'
export const MENU_CATEGORY = 'menu-category'
export const MENU_CODE = 'menu-code'
export const PAYMENT_PROCESS = 'payment-process'
export const AUTH_TOKENS_HOME = 'authTokens'
export const AUTH_TOKENS_REFRESH_HOME = 'refreshTokenHome'
export const MSISDN_HOME = 'msisdn'
export const MSISDN_MINECO = 'msisdnMineco'
export const AUTH_TOKENS_MINECO = 'authTokensMineco'
export const ADM_PROCESS = 'adm-process'

export const labels = {
  GREETINGS: '¡Hola!',
  COMPLETE_DATA_PAYMENT: 'Completa tus datos de pago',
  MORE_OPTIONS: 'Más opciones de pago',
  PAY_NOW: 'Pagar Ahora',
  PACKAGE: 'Paquete',
  PRICE: 'Precio',
  VALIDITY: 'Vigencia',
  VIEW_MORE_BENEFITS: 'Ver detalle',
  VIEW_LESS_BENEFITS: 'Ocultar detalle',
  VIEW_MORE_DETAILS: 'Ver más detalle',
  VIEW_LESS_DETAILS: 'Ocultar',
  VIEW_MORE_OPTIONS: 'Ver más Opciones',
  LAST_DIGITS: 'Tarjeta terminada en',
  CUENTA_BI: 'Débito a Cuenta',
  REMEMBER_DATE: 'Recordar mi fecha de vencimiento',
  EXPIRATION_DATE: 'MM/AA',
  PROCESSING_PAYMENT:
    'Estamos procesando la información de pago. Esto puede demorar, espera un momento...',
  PROCESSING_BILLING:
    'Estamos procesando tu factura. Esto puede demorar, espera un momento...',
  PROCESSING_SESSION:
    'Estamos procesando la información. Esto puede demorar, espera un momento...',
  PURCHASE_MADE: '🙌  ¡Gracias por tu Compra!',
  PAYMENT_MADE: ' Tu pago fue realizado correctamente',
  SELECTED_PACKAGE: 'Paquete Comprado',
  PAID_PACKAGE: 'Paquete Pagado',
  VIEW_DETAILS: 'Ver detalles',
  REMEMBER_WILL_SEND_SMS: 'Recuerda que te enviaremos un SMS cuando tu',
  SELECTED_ACTIVE_PACKAGE: 'paquete se encuentre activo',
  SELECTED_ACTIVE_RECHARGE: 'recarga se encuentre activa',
  SELECT_TARGET_TO_DELETE: 'Selecciona la tarjeta que deseas eliminar',
  SELECT_NOTI_TO_DELETE:
    'Selecciona el tipo de notificaciones que quieres cancelar',
  CARD_ENDED: 'Tarjeta terminada en',
  FORGET_CARD: 'Eliminar mi tarjeta',
  FORGOTTEN:
    'La fecha de vencimiento de tu tarjeta ha sido eliminada correctamente.',
  FORGOTTEN_NOTI:
    'Las notificaciones han sido canceladas. Ya no recibirás notificaciones interactivas de Claro te Presta.',
  WARNING_TITLE: 'Aviso',
  WARNING_TITLE_NOTI:
    'Al cancelar estas notificaciones no podrás enterarte mediante mensajes interactivos que tienes disponible un préstamo de paquete cuando necesitas llamar y no tienes saldo.',
  WARNING_INFO:
    'Al eliminar la fecha de vencimiento de tu tarjeta perderás la facilidad de realizar compras desde la funcionalidad de SAT y tendrás que volver a ingresar los datos en cada compra.',
  FORGET: 'Eliminar',
  FORGET_NOTI: 'Confirmar',
  INCLUDE: 'Incluye',
  NEWCARD: 'Ingresar una nueva tarjeta',
  NEWPAYMENTMETHOD: 'Ingresar una nueva forma de pago:',
  VIEW_OTHER_PAYMENT_OPTIONS: 'Ver tus otras opciones de pago',
  CARD: 'Tarjeta Crédito / Débito',
  BANK_ACCOUNT: 'Cuenta bancaria',
  ADDCARD: 'Ingresa los datos de tu tarjeta de crédito/débito',
  PAYMENT_TITLE: 'Completa tus datos de pago:',
  HOMEPAGE: 'Ir a Página principal',
  REGRESAR_BI: 'Presiona “Regresar” para la pantalla anterior.',
  REGRESAR: 'Regresar',
  TERMS_AND_CONDITIONS: {
    parrafo_1: 'COMPRA DE PAQUETES Y RECARGAS CON MEDIOS ELECTRÓNICOS DE PAGO:',
    parrafo_2: 'LÍMITE DE RESPONSABILIDAD:',
    parrafo_3: 'MÉTODOS DE PAGO:',
    parrafo_4: 'SERVICIO DE COBRO AUTOMÁTICO:',
    parrafo_5: 'CANCELACIÓN DE LA AFILIACIÓN A COBRO AUTOMÁTICO:',
    parrafo_6: 'CONDICIONES DE USO DEL SERVICIO:',
    parrafo_7: 'PROPIEDAD INTELECTUAL:',
    parrafo_8: 'INFORMACIÓN PROPORCIONADA POR EL USUARIO',
    parrafo_9: 'DISPOSITIVOS:',
    parrafo_10: 'MENSAJES DE TEXTO',
    parrafo_11: 'PAGO',
    parrafo_12: 'RENUNCIA.',
    parrafo_13: 'ACLARACIONES DEL USUARIO:',
    parrafo_14: 'LEY APLICABLE Y JURISDICCIÓN:',
  },
}

export const URL_KEY_TO_LOCALSTORAGE_CALLED_ME =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_WHO_CALLED_ME'
export const URL_KEY_TO_LOCALSTORAGE_BNPL =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_BUY_NOW_PAY_LATER'
export const URL_KEY_TO_LOCALSTORAGE_WALLET_RECOVERY =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_WALLET_RECOVERY'
export const WALLET_RECOVERY = 'WALLET_RECOVERY'
export const URL_KEY_TO_LOCALSTORAGE_CBK_USSD =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_CBK_USSD'
export const URL_KEY_TO_LOCALSTORAGE_BILLING =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_BILLING'
export const URL_KEY_TO_LOCALSTORAGE_ADM =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_ADM'
export const URL_KEY_TO_LOCALSTORAGE_SESSIONBI =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_SESSIONBI'
export const URL_KEY_TO_LOCALSTORAGE_DELETEACCOUNTBI =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_DELETEACCOUNTBI'
export const URL_KEY_TO_LOCALSTORAGE_RECURRENCE =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_RECURRENCE'
export const URL_KEY_TO_LOCALSTORAGE_SEND_A_RECHARGE =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_SEND_A_RECHARGE'
export const URL_KEY_TO_LOCALSTORAGE_DEMOBI =
  'PATH_AND_SEARCH_CODE_TO_CLARO_PAY_DEMOBI'
