import React, { useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { postAxiosService } from '../../services/postAxiosService'

import localRoutes from '../../constants/routes'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import { RESPONSE_CODE } from '../../constants/responseCode'

import handleRedirectHome from '../HandleRedirectHome'
import CustomButton from '../CustomButton'

const Billing = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const {
    setProcessingBilling,
    sendMetrics,
    setCanClickButton,
    canClickButton,
    setResponseServiceBilling,
    codeBilling,
    billingData,
    walletRecoveryData,
    cbkUssdData,
    homeData,
    callMeData,
    recurrenceData,
    sendARechargeData,
  } = claroPayContext

  const {
    OK_CLARO_BILLING,
    FAIL_SERVER_CLARO_PAY,
    FAIL_SERVER_CLARO_BILLING,
    SESSION_CODE_EXPIRED_BILLING,
    BILLING_HAS_ALREADY_BEEN_GENERATED,
    REJECT_VALIDATION_BILLING,
    NO_RESPONSE_FROM_PROVIDER_BILLING,
  } = RESPONSE_CODE

  const {
    routesClaroPay: { claroBillingProcess },
  } = localRoutes

  const [name, setName] = useState('')
  const [errorName, setErrorName] = useState('')
  const [id, setId] = useState('')
  const [errorId, setErrorId] = useState('')
  const [address, setAddress] = useState('')
  const [errorAddress, setErrorAddress] = useState('')
  const [mail, setMail] = useState('')
  const [errorMail, setErrorMail] = useState('')
  const [inactive, setInactive] = useState(true)

  const billing = async (code, name, id, typeId, address, mail) => {
    try {
      const data = { code, name, id, typeId, address, mail }
      const url = BACK_END_ROUTES.billingSend
      const response = await postAxiosService(url, data)
      return await processBillingResponse(response)
    } catch (error) {
      return await processBillingResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processBillingResponse = async response => {
    const responseMap = {
      [OK_CLARO_BILLING.code]: OK_CLARO_BILLING,
      [FAIL_SERVER_CLARO_BILLING.code]: FAIL_SERVER_CLARO_BILLING,
      [SESSION_CODE_EXPIRED_BILLING.code]: SESSION_CODE_EXPIRED_BILLING,
      [BILLING_HAS_ALREADY_BEEN_GENERATED.code]: BILLING_HAS_ALREADY_BEEN_GENERATED,
      [REJECT_VALIDATION_BILLING.code]: REJECT_VALIDATION_BILLING,
      [NO_RESPONSE_FROM_PROVIDER_BILLING.code]: NO_RESPONSE_FROM_PROVIDER_BILLING,
      [FAIL_SERVER_CLARO_PAY.code]: FAIL_SERVER_CLARO_PAY,
    }

    return responseMap[response.responseCode] || response
  }

  const isValidEmail = email => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailPattern.test(email)
  }

  const fieldConfig = {
    name: {
      errorMessage: 'Por favor ingresa el Nombre y Apellido para Facturar',
    },
    id: {
      errorMessage: 'Por favor ingresa el Número de NIT para Facturar',
    },
    address: {
      errorMessage: 'Por favor ingresa la Dirección para Facturar',
    },
    mail: {
      errorMessage: 'Por favor ingresa el Correo Electrónico para Facturar',
      validation: isValidEmail,
      invalidMessage: 'El formato del Correo Electrónico no es válido',
    },
  }

  const validateForm = () => {
    if (
      name !== '' &&
      id !== '' &&
      address !== '' &&
      mail !== '' &&
      isValidEmail(mail)
    ) {
      setCanClickButton(true)
    } else {
      setCanClickButton(false)
    }
  }

  useEffect(() => {
    validateForm()
  }, [name, id, address, mail])

  useEffect(() => {
    if (!billingData) {
      if (inactive) {
        const timeoutId = setTimeout(() => {
          handleRedirectHome()
        }, 10000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [billingData, inactive])

  const handleChange = field => e => {
    const value = e.target.value
    const config = fieldConfig[field]
    setInactive(false)

    switch (field) {
      case 'name':
        setName(value)
        break
      case 'id':
        setId(value)
        break
      case 'address':
        setAddress(value)
        break
      case 'mail':
        setMail(value)
        break
      default:
        break
    }

    if (value === '') {
      setError(field, config.errorMessage)
    } else if (config.validation && !config.validation(value)) {
      setError(field, config.invalidMessage)
    } else {
      setError(field, '')
    }

    validateForm()
  }

  const setError = (field, message) => {
    switch (field) {
      case 'name':
        setErrorName(message)
        break
      case 'id':
        setErrorId(message)
        break
      case 'address':
        setErrorAddress(message)
        break
      case 'mail':
        setErrorMail(message)
        break
      default:
        break
    }
  }

  const isAnyDataPresent = () => {
    return (
      walletRecoveryData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  const metricHandleService = () => {
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'ExitosoFel',
        metricName: 'FEL-CompletarDatos_Enviar',
      }
      sendMetrics(metricsData)
    } else if (billingData) {
      const metricsData = {
        metricType: 'Completar Datos',
        metricName: 'Completar Datos',
      }
      sendMetrics(metricsData)
    }
  }

  const handleService = async () => {
    setResponseServiceBilling(null)
    setCanClickButton(false)
    metricHandleService()
    setProcessingBilling(true)
    history.push(claroBillingProcess)
    const response = await billing(codeBilling, name, id, 4, address, mail)
    setResponseServiceBilling(response)
  }

  const handleRedirectHomeSend = async () => {
    if (isAnyDataPresent()) {
      const metricsData = {
        metricType: 'ExitosoFel',
        metricName: 'FEL-CompletarDatos_Regresar',
      }
      await sendMetrics(metricsData)
    }
    handleRedirectHome()
  }

  return (
    <>
      {codeBilling && (
        <>
          <div className="claro__payment__process__sucess__billing">
            <div className="claro__payment__process__success__billing__title">
              <span className="claro__payment__process__success__billing__title__textBlack">
                Ingresa tus datos para completar tu factura. {''}
              </span>
              <span className="claro__payment__process__success__billing__title__textRed">
                En caso de que no desees o no ingreses tus datos de facturación,
                se generará una factura como {''}
              </span>
              <span className="claro__payment__process__success__billing__title__textBoldRed">
                Consumidor Final.
              </span>
            </div>

            <div className="claro__payment__process__sucess__billing__content">
              <div className="claro__payment__process__sucess__billing__content__input">
                <label className="claro__payment__process__sucess__billing__content__input__label">
                  Nombre y Apellido:
                  <span className="claro__payment__process__sucess__billing__content__input__label required">
                    *
                  </span>
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Escribe tu Nombre y Apellido"
                  value={name}
                  onChange={handleChange('name')}
                  onClick={handleChange('name')}
                  autoFocus={true}
                  className={errorName ? 'error' : ''}
                />
                {errorName && (
                  <div className="claro__payment__process__sucess__billing__content__input__errorMessage">
                    {errorName}
                  </div>
                )}
              </div>

              <div className="claro__payment__process__sucess__billing__content__input">
                <label className="claro__payment__process__sucess__billing__content__input__label">
                  NIT:
                  <span className="claro__payment__process__sucess__billing__content__input__label required">
                    *
                  </span>
                </label>
                <input
                  id="id"
                  type="text"
                  placeholder="Escribe tu Número de NIT"
                  value={id}
                  onChange={handleChange('id')}
                  onClick={handleChange('id')}
                  maxLength={9}
                  className={errorId ? 'error' : ''}
                />
                {errorId && (
                  <div className="claro__payment__process__sucess__billing__content__input__errorMessage">
                    {errorId}
                  </div>
                )}
              </div>

              <div className="claro__payment__process__sucess__billing__content__input">
                <label className="claro__payment__process__sucess__billing__content__input__label">
                  Dirección de Facturación:
                  <span className="claro__payment__process__sucess__billing__content__input__label required">
                    *
                  </span>
                </label>
                <input
                  id="address"
                  type="text"
                  placeholder="Escribe tu Dirección de Facturación"
                  value={address}
                  onChange={handleChange('address')}
                  onClick={handleChange('address')}
                  className={errorAddress ? 'error' : ''}
                />
                {errorAddress && (
                  <div className="claro__payment__process__sucess__billing__content__input__errorMessage">
                    {errorAddress}
                  </div>
                )}
              </div>

              <div className="claro__payment__process__sucess__billing__content__input">
                <label className="claro__payment__process__sucess__billing__content__input__label">
                  Correo Electrónico para enviar Factura:
                  <span className="claro__payment__process__sucess__billing__content__input__label required">
                    *
                  </span>
                </label>
                <input
                  id="mail"
                  type="email"
                  placeholder="Escribe tu Correo Electrónico"
                  value={mail}
                  onChange={handleChange('mail')}
                  onClick={handleChange('mail')}
                  className={errorMail ? 'error' : ''}
                />
                {errorMail && (
                  <div className="claro__payment__process__sucess__billing__content__input__errorMessage">
                    {errorMail}
                  </div>
                )}
              </div>
              <div className="container__btnConfirmar">
                <CustomButton
                  text="Regresar"
                  onClick={handleRedirectHomeSend}
                  customClass={'btn__regresar'}
                  enabled={true}
                />

                <CustomButton
                  text="Enviar"
                  onClick={handleService}
                  customClass={'btn__enviar'}
                  enabled={canClickButton}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Billing
