import React, { useContext, useEffect, useState } from 'react'

import CustomSwitch from '../CustomSwitch'
import { lastTwoDigitsYear, penultimateTwoDigitsYear } from '../DateYear'
import { nextInput } from '../NextInput'

import { labels } from '../../constants/ClaroPayConstants'

import ClaroPayContext from '../../context/ClaroPayContext'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'

const CardInformation = ({
  titleCarCss,
  onChangeMonth,
  onChangeYear,
  onChangeSwitch,
  isVisible = false,
  creditCard,
  sendMetrics,
}) => {
  const claroPayContext = useContext(ClaroPayContext)

  const { LAST_DIGITS, EXPIRATION_DATE, REMEMBER_DATE } = labels

  const [isVisibleInformation, setIsVisibleInformation] = useState(isVisible)

  const [MM, setMM] = useState('')
  const [AA, setAA] = useState('')
  const [isSelected, setIsSelected] = useState(false)

  const {
    setSelectedSavedCard,
    setIsVisibleSectionAddNewCard,
    setIsVisibleAddNewPaymentMethod,
    setIsVisibleAddNewAccountBi,
    setSelectAccountBi,
    getImage,
  } = claroPayContext

  const handleChangeMonth = event => {
    if (event.target.value.length <= 2) {
      if (
        event.target.value === '' ||
        (Number(event.target.value) >= 0 && Number(event.target.value) < 13)
      ) {
        onChangeMonth(event.target.value)
        setMM(event.target.value)
        if (event.target.value.length === 2) nextInput(event)
      }
    }
  }

  const handleChangeYear = event => {
    const value = event.target.value
    const numericValue = Number(value)

    const applyChange = () => {
      onChangeYear(value)
      setAA(value)
    }

    if (value.length <= 2) {
      if (
        (AA === '' && numericValue >= Number(penultimateTwoDigitsYear)) ||
        numericValue >= Number(lastTwoDigitsYear) ||
        (AA !== '' && AA.length === 2) ||
        (AA !== '' && value === '')
      ) {
        applyChange()
      }
    }
  }

  const handleChangeSwitch = value => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: `TCFecha-${creditCard.position}`,
    }
    if (sendMetrics !== undefined && value) {
      sendMetrics(metricsData)
    }
    setIsSelected(value)
    onChangeSwitch(value)
  }

  const handleVisibility = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: `PagarTC-${creditCard.position}`,
    }
    if (sendMetrics !== undefined && !isVisibleInformation) {
      sendMetrics(metricsData)
    }
    setSelectedSavedCard(creditCard)
    setIsVisibleInformation(!isVisibleInformation)
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleAddNewAccountBi(false)
    setIsVisibleSectionAddNewCard(false)
    setSelectAccountBi(null)
    setAA('')
    setMM('')
    onChangeMonth('')
    onChangeYear('')
    onChangeSwitch(false)
    setIsSelected(false)
  }

  useEffect(() => {
    setIsVisibleInformation(isVisible)
  }, [isVisible])

  return (
    <div className="card__information_container">
      <div className="card__information row" onClick={handleVisibility}>
        <p className={`col-10 d-flex align-items-center ${titleCarCss}`}>
          <img
            className="mr-2"
            src={`${isVisibleInformation ? circleAccepted : circleNotAccepted}`}
            alt={`${LAST_DIGITS}: ${creditCard.lastDigits}`}
          />
          {`${LAST_DIGITS}: ${creditCard.lastDigits}`}
        </p>
        <p className="col-2 text-right">
          <img
            src={getImage(creditCard.icon)}
            alt="social-icon"
            style={{ height: '32px', width: '32px' }}
          />
        </p>
      </div>

      {isVisibleInformation && (
        <form>
          <div className="card__expiration__information">
            <p>{EXPIRATION_DATE}</p>

            <div className="due__date">
              <input
                placeholder="00"
                maxLength="2"
                className="input__due__date"
                type="number"
                name="month"
                value={MM}
                autoFocus={true}
                onChange={handleChangeMonth}
              />
              /{' '}
              <input
                placeholder="00"
                maxLength="2"
                className="input__due__date"
                type="number"
                name="year"
                value={AA}
                onChange={handleChangeYear}
              />
            </div>
          </div>

          <CustomSwitch
            isSelected={isSelected}
            onClick={handleChangeSwitch}
            text={REMEMBER_DATE}
          />
        </form>
      )}
    </div>
  )
}
export default CardInformation
