import React from 'react'

import menuIconSuperpacks from '../../assets/img/menuIcon-Superpacks.svg'
import menuIconRecarga from '../../assets/img/menuIcon-Recarga.svg'
import menuIconClaroTePresta from '../../assets/img/menuIcon-ClaroTePresta.svg'
import menuIconPagaTuPrestamo from '../../assets/img/menuIcon-PagaTuPrestamo.svg'
import menuIconEnviaUnaRecarga from '../../assets/img/menuIcon-EnviaUnaRecarga.svg'
import cerrarSesionImg from '../../assets/img/cerrarSesion.svg'

const menuOptions = [
  { id: 'Superpacks', icon: menuIconSuperpacks, label: 'Superpacks' },
  { id: 'Recargas', icon: menuIconRecarga, label: 'Recargas' },
  { id: 'ADM', icon: menuIconClaroTePresta, label: 'Claro Te Presta' },
  { id: 'PagarDeuda', icon: menuIconPagaTuPrestamo, label: 'Paga tu Préstamo' },
  {
    id: 'EnviaRecarga',
    icon: menuIconEnviaUnaRecarga,
    label: 'Envía una Recarga',
  },
]

const MenuOption = ({ icon, label, selected, onClick, menuType }) => (
  <div
    className={`claro__layout__nav__${menuType}__option ${
      selected ? 'seleccionada' : ''
    }`}
    onClick={onClick}
  >
    <img src={icon} alt={label} className={`icono-${menuType}`} />
    <span>{label}</span>
  </div>
)

const Menu = ({
  opcionSeleccionada,
  toggleMenu,
  handleOptionClick,
  isAuthenticated,
  menuVisible,
}) => {
  const renderMenuOptions = menuType =>
    menuOptions.map(({ id, icon, label }) => (
      <MenuOption
        key={id}
        id={id}
        icon={icon}
        label={label}
        selected={opcionSeleccionada === id}
        onClick={() => handleOptionClick(id, menuType)}
        menuType={menuType}
      />
    ))

  return (
    <div className="claro__layout__nav">
      <div
        role="button"
        className={`claro__layout__nav__menuV ${menuVisible ? 'visible' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {renderMenuOptions('menuV')}
        {isAuthenticated && (
          <MenuOption
            id="CerrarSesion"
            icon={cerrarSesionImg}
            label="Cerrar Sesión"
            selected={opcionSeleccionada === 'CerrarSesion'}
            onClick={() => handleOptionClick('CerrarSesion', 'menuV')}
            menuType="menuV"
          />
        )}
      </div>

      <div className="claro__layout__nav__menuH">
        {renderMenuOptions('menuH')}
      </div>
    </div>
  )
}

export default Menu
