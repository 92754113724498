import React from 'react'
import CustomButton from '../CustomButton'

const PaymentActionsNext = ({ textBtn, enabled, onClick }) => {
  return (
    <div className="claro__payment__actions">
      <CustomButton
        text={textBtn}
        onClick={onClick}
        customClass={'btn-next'}
        enabled={enabled}
      />
    </div>
  )
}

export default PaymentActionsNext
