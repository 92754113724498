export const DeepLinkBi = responseRequestId => {
  const domainDeeplink = 'https://belappgt.bi.com.gt/'
  const typeFonyou = 'type=walletWeb'
  const userAgent = navigator.userAgent
  const currentURL =
    window.location.origin +
    '/landing/failure?' +
    window.location.pathname +
    window.location.search
  const failureURL = `failureURL=${currentURL}`

  let deeplink = ''
  let appStoreUrl = ''

  if (/(android|iphone|ipad)/i.test(userAgent)) {
    if (/android/i.test(userAgent)) {
      const start = 'fintech?'
      const nameFonyou = 'fintech_name=Claro'
      const idFonyou = 'fintech_id=202309190905'
      const logoFonyou =
        'fintech_logo=https://recargo.miclaro.com.gt/img/logo-claro.jpg'
      deeplink = `${domainDeeplink}${start}${nameFonyou}&${idFonyou}&${logoFonyou}&${responseRequestId}&${typeFonyou}&${failureURL}`
      appStoreUrl =
        'https://play.google.com/store/apps/details?id=gt.com.bi.bienlinea&hl=es_GT&gl=US&pli=1'
    } else if (/iphone|ipad/i.test(userAgent)) {
      const start = 'fl?'
      const nameFonyou = 'appName=Claro'
      const idFonyou = 'appId=202309190905'
      const logoFonyou =
        'logoURL=https://recargo.miclaro.com.gt/img/logo-claro.jpg'
      deeplink = `${domainDeeplink}${start}${nameFonyou}&${idFonyou}&${logoFonyou}&${responseRequestId}&${typeFonyou}&${failureURL}`
      appStoreUrl = 'https://apps.apple.com/gt/app/bi-en-l%C3%ADnea/id510761055'
    }
  } else {
    deeplink = 'https://www.corporacionbi.com/gt/bancoindustrial/'
    appStoreUrl = 'https://www.corporacionbi.com/gt/bancoindustrial/'
  }

  return { deeplink, appStoreUrl }
}
