import React from 'react'

import processingImage from '../../assets/img/processingPayment.svg'

const LoadingScreen = () => {
  return (
    <div className="claro__layout__container">
      <div className="claro__payment__process">
        <div className="claro__payment__process__waiting">
          <img
            className="claro__payment__process__image"
            src={processingImage}
            alt="waiting"
          />
          <p className="claro__payment__process__generalMessage">
            Espera un momento… Estamos buscando la mejor opción para ti{' '}
            <span role="img" aria-label="Estrella">
              🌟
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
