import { setLocalStorage, clearLocalStorage } from '../../services/storage'
import { RESPONSE_CODE } from '../../constants/responseCode'
import {
  PAYMENT_PROCESS,
  PRODUCT_SELECTED_HOME,
} from '../../constants/ClaroPayConstants'

const ProcessPaymentResponse = () => {
  const {
    OK_CLARO_PAY,
    SESSION_CODE_EXPIRED,
    ANTIFRAUD_LOCK,
    TRANSACTION_DECLINED,
    VALIDATION_CODE_EXPIRED,
    VALIDATION_EXPIRATION_DATE_ERROR,
    FAIL_SERVER_CLARO_PAY,
    VALIDATION_NO_LONGER_HAVE_DEBT,
    DATOS_INCORRECTOS,
    PAYMENT_SERVICE_ERROR,
    INSUFFICIENT_FUNDS_BI,
    ACCOUNT_INACTIVE_BI,
    WITHOUT_PROVISIONING_BI,
    CONNECTION_FAILURE_BI,
    ERROR_GENERAL_DELETE_ACCOUNT_BI,
    ERROR_LIMIT_BI,
    PAYMENT_TIMEOUT_BI,
    PROVISION_SERVICE_BEFORE,
  } = RESPONSE_CODE

  const processPaymentResponse = async (
    response,
    handleRedirect,
    clearData,
    setCodeBilling,
  ) => {
    setLocalStorage(PAYMENT_PROCESS, handleRedirect())
    clearData()

    switch (response.responseCode) {
      case OK_CLARO_PAY.code:
        const code = response.responseContent?.code
        if (code) {
          setCodeBilling(code)
        }
        clearLocalStorage(PRODUCT_SELECTED_HOME)
        return OK_CLARO_PAY

      case SESSION_CODE_EXPIRED.code:
        return SESSION_CODE_EXPIRED

      case ANTIFRAUD_LOCK.code:
        return ANTIFRAUD_LOCK

      case TRANSACTION_DECLINED.code:
        return TRANSACTION_DECLINED

      case VALIDATION_CODE_EXPIRED.code:
        return VALIDATION_CODE_EXPIRED

      case VALIDATION_EXPIRATION_DATE_ERROR.code:
        return VALIDATION_EXPIRATION_DATE_ERROR

      case FAIL_SERVER_CLARO_PAY.code:
        return FAIL_SERVER_CLARO_PAY

      case VALIDATION_NO_LONGER_HAVE_DEBT.code:
        return VALIDATION_NO_LONGER_HAVE_DEBT

      case DATOS_INCORRECTOS.code:
        return DATOS_INCORRECTOS

      case PAYMENT_SERVICE_ERROR.code:
        return PAYMENT_SERVICE_ERROR

      case INSUFFICIENT_FUNDS_BI.code:
        return INSUFFICIENT_FUNDS_BI

      case ACCOUNT_INACTIVE_BI.code:
        return ACCOUNT_INACTIVE_BI

      case WITHOUT_PROVISIONING_BI.code:
        return WITHOUT_PROVISIONING_BI

      case CONNECTION_FAILURE_BI.code:
        return CONNECTION_FAILURE_BI

      case ERROR_GENERAL_DELETE_ACCOUNT_BI.code:
        return ERROR_GENERAL_DELETE_ACCOUNT_BI

      case ERROR_LIMIT_BI.code:
        return ERROR_LIMIT_BI

      case PAYMENT_TIMEOUT_BI.code:
        return PAYMENT_TIMEOUT_BI

      case PROVISION_SERVICE_BEFORE.code:
        return PROVISION_SERVICE_BEFORE

      default:
        return FAIL_SERVER_CLARO_PAY
    }
  }

  return { processPaymentResponse }
}

export default ProcessPaymentResponse
