import { labels } from '../../constants/ClaroPayConstants'

const MoreOptions = ({ handleSelectedProduct }) => {
  const { VIEW_MORE_OPTIONS } = labels

  return (
    <div className="claro__more__options">
      <u className="claro__more__options__text" onClick={handleSelectedProduct}>
        {VIEW_MORE_OPTIONS}
      </u>
    </div>
  )
}

export default MoreOptions
