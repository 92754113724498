import React, { useContext, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import CardInformation from '../../components/CardInformation'
import CustomSwitch from '../../components/CustomSwitch'
import {
  lastTwoDigitsYear,
  penultimateTwoDigitsYear,
} from '../../components/DateYear'
import { nextInput } from '../../components/NextInput'

import {
  labels,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'

const ClaroPayPaymentOptions = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const { NEWCARD, PAYMENT_TITLE } = labels

  const {
    recurrenceData,
    selectedSavedCard,
    setSelectedSavedCard,
    saveCard,
    setSaveCard,
    setYear,
    setMonth,
    saveNewCard,
    setSaveNewCard,
    yearNewCard,
    setYearNewCard,
    monthNewCard,
    setMonthNewCard,
    cvv,
    setCvv,
    numberCard,
    setNumberCard,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    maxLengthCvv,
    setMaxLengthCvv,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    callMeData,
    bnplData,
    walletRecoveryData,
    minecoBecasData,
    cbkUssdData,
    homeData,
    sendMetrics,
    code,
    sendARechargeData,
    setProcessingPayment,
    processingPayment,
    setResponseService,
    responseService,
    setCanClickButton,
    setCodeBilling,
  } = claroPayContext

  useEffect(() => {
    setProcessingPayment(false)
    setResponseService(null)
    clearLocalStorage(METRICS_SESSION)
    clearLocalStorage(PAYMENT_PROCESS)
    setCodeBilling(null)
    const procesoPago = getLocalStorage(PROCESO_PAGO)
    if (procesoPago) {
      setCanClickButton(false)
      const timeoutId = setTimeout(() => {
        setCanClickButton(true)
        clearLocalStorage(PROCESO_PAGO)
      }, 30000)

      return () => clearTimeout(timeoutId)
    } else {
      setCanClickButton(true)
    }
  }, [responseService, processingPayment])

  let creditCardList = []
  let showAddNewCardSection = true

  creditCardList =
    bnplData?.creditCardList ||
    walletRecoveryData?.creditCardList ||
    minecoBecasData?.creditCardList ||
    cbkUssdData?.creditCardList ||
    homeData?.tokenList ||
    callMeData?.creditCardList ||
    recurrenceData?.creditCardList ||
    sendARechargeData?.creditCardList ||
    []

  if (creditCardList) {
    for (let i = 0; i < creditCardList.length; i++) {
      creditCardList[i].position = i + 1
    }
  }

  const isAnyDataPresent = () => {
    return (
      walletRecoveryData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  const handleSwitchClick = () => {
    setSaveNewCard(prevState => {
      if (!prevState) {
        if (isAnyDataPresent()) {
          const metricsData = {
            metricType: 'CheckOut_',
            metricName: `SaveCard-${creditCardList.length + 1}`,
          }
          sendMetrics(metricsData)
        }
      }
      return !prevState
    })
  }

  const handleAddNewCard = () => {
    if (!isVisibleSectionAddNewCard && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleSectionAddNewCard(!isVisibleSectionAddNewCard)
    setSelectedSavedCard({})
    setMonth('')
    setYear('')
    setSaveCard(false)
    setMonthNewCard('')
    setYearNewCard('')
    setCvv('')
    setNumberCard('')
    setSaveNewCard(false)
  }

  const handleChangeMonth = event => {
    if (event.target.value.length <= 2) {
      if (
        event.target.value === '' ||
        (Number(event.target.value) >= 0 && Number(event.target.value) < 13)
      ) {
        setMonthNewCard(event.target.value)
        if (event.target.value.length === 2) nextInput(event)
      }
    }
  }

  const handleChangeYear = event => {
    const inputValue = event.target.value
    if (inputValue.length <= 2) {
      const isValidYear =
        (yearNewCard === '' &&
          Number(inputValue) >= Number(penultimateTwoDigitsYear)) ||
        Number(inputValue) >= Number(lastTwoDigitsYear) ||
        (yearNewCard !== '' && yearNewCard.length === 2) ||
        (yearNewCard !== '' && inputValue === '')

      if (isValidYear) {
        setYearNewCard(inputValue)
      }

      if (inputValue.length === 2) {
        nextInput(event)
      }
    }
  }

  const handleChangeCvv = event => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }

    if (event.target.value.length <= maxLengthCvv) {
      setCvv(event.target.value)
    }
  }

  const handleChangeCardNumber = event => {
    if (event.target.value.length <= maxLengthNumberCard) {
      setNumberCard(event.target.value)
    }
    if (event.target.value.length === maxLengthNumberCard) {
      nextInput(event)
    }
  }

  useEffect(() => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }
  }, [numberCard])

  useEffect(() => {
    if (creditCardList.length === 0) {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
        }
        handleSendMetrics(metricsData)
      }
      setIsVisibleSectionAddNewCard(true)
    } else if (creditCardList.length === 1) {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `PagarTC-${creditCardList.length}`,
        }
        handleSendMetrics(metricsData)
      }
      setIsVisibleSectionAddNewCard(false)
      setSelectedSavedCard(creditCardList[0])
    }
  }, [code])

  const handleSendMetrics = metricsData => {
    if (homeData || code) {
      sendMetrics(metricsData)
    }
  }

  return (
    <div className="claro__container__payment__options">
      <div>
        {creditCardList !== null && (
          <>
            <div className="title__payment__options">
              <p className="">{PAYMENT_TITLE}</p>
            </div>
            {creditCardList.map(item => {
              const isVisible =
                isVisibleSectionAddNewCard === true
                  ? false
                  : item.lastDigits === selectedSavedCard.lastDigits

              return (
                <CardInformation
                  key={item.lastDigits}
                  isSelected={saveCard}
                  onChangeMonth={setMonth}
                  onChangeYear={setYear}
                  onChangeSwitch={setSaveCard}
                  isVisible={isVisible}
                  creditCard={item}
                  titleCarCss="text__add_card mb-0"
                  sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
                />
              )
            })}
          </>
        )}

        {showAddNewCardSection === true && creditCardList.length <= 2 && (
          <p className="text__add_card" onClick={handleAddNewCard}>
            <img
              className="mr-2"
              src={`${
                isVisibleSectionAddNewCard ? circleAccepted : circleNotAccepted
              }`}
              alt={NEWCARD}
            />
            {NEWCARD}
          </p>
        )}

        {showAddNewCardSection === true && isVisibleSectionAddNewCard === true && (
          <form className="new__card__container mx-3">
            <div className="number__card">
              <p className="titles_info_card">Número de tarjeta</p>
              <input
                placeholder="Ingresa el número de tu tarjeta"
                maxLength="2"
                className="data_info_card"
                type="number"
                name="number_card"
                value={numberCard}
                autoFocus={true}
                onChange={handleChangeCardNumber}
              />
            </div>
            <div className="expiration__date">
              <p className="titles_info_card">MM/AA</p>
              <div className="month__and__year">
                <input
                  placeholder="00"
                  maxLength="2"
                  className=" data__expiration__date"
                  type="number"
                  name="month"
                  value={monthNewCard}
                  onChange={handleChangeMonth}
                />
                /
                <input
                  placeholder="00"
                  maxLength="2"
                  className=" data__expiration__date"
                  type="number"
                  name="year"
                  value={yearNewCard}
                  onChange={handleChangeYear}
                />
              </div>
            </div>
            <div className="cvv">
              <p className="titles_info_card">CVV</p>
              <input
                placeholder="000"
                maxLength="4"
                className="data_info_card"
                type="number"
                name="cvv"
                value={cvv}
                onChange={handleChangeCvv}
              />
            </div>
            <CustomSwitch
              isSelected={saveNewCard}
              onClick={handleSwitchClick}
              text={'Guardar datos de tarjeta, para futuras compras'}
            />
          </form>
        )}
      </div>
    </div>
  )
}

export default ClaroPayPaymentOptions
