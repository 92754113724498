import React, { useContext, useEffect } from 'react'

import { Route } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(ClaroPayContext)

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = window.location.origin
    }
  }, [isAuthenticated])

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Component {...props} /> : null)}
    />
  )
}

export default PrivateRoute
