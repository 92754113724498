import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import TermsAndConditionsGT from './TyC_GT'
import TermsAndConditionsSV from './TyC_SV'
import TermsAndConditionsHN from './TyC_HN'
import TermsAndConditionsNI from './TyC_NI'
import TermsAndConditionsCR from './TyC_CR'

import Country from '../../constants/Country'

const TyC_Handler = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const handleRedirect = () => {
    history.go(-1)
  }

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollUp()
    }, 500)

    return () => clearTimeout(timer)
  }, [countryCode])

  let TermsAndConditions = null

  if (countryCode === '502') {
    TermsAndConditions = TermsAndConditionsGT
  } else if (countryCode === '503') {
    TermsAndConditions = TermsAndConditionsSV
  } else if (countryCode === '504') {
    TermsAndConditions = TermsAndConditionsHN
  } else if (countryCode === '505') {
    TermsAndConditions = TermsAndConditionsNI
  } else if (countryCode === '506') {
    TermsAndConditions = TermsAndConditionsCR
  }

  return (
    <div className="claro__termsAndConditionsOrPrivacity">
      <div className="claro__termsAndConditionsOrPrivacity__goHome">
        <div onClick={handleRedirect}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.509"
            height="25.377"
            viewBox="0 0 14.509 25.377"
          >
            <path
              className="icon"
              id="Icon_ionic-ios-arrow-forward"
              data-name="Icon ionic-ios-arrow-forward"
              d="M21.382,18.88l-9.6-9.6a1.806,1.806,0,0,1,0-2.561,1.829,1.829,0,0,1,2.569,0L25.228,17.6a1.81,1.81,0,0,1,.053,2.5L14.356,31.044a1.814,1.814,0,0,1-2.569-2.561Z"
              transform="translate(25.756 31.573) rotate(180)"
              fill="#2f2f2f"
            />
          </svg>
          Regresar
        </div>
      </div>
      <h1>TÉRMINOS Y CONDICIONES</h1>
      <h3>COMPRA DE PAQUETES Y RECARGAS USANDO MEDIOS ELECTRÓNICOS DE PAGO.</h3>
      <TermsAndConditions />
    </div>
  )
}

export default TyC_Handler
