import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/effect-coverflow'

import './pruebas.scss'

import { getImage } from '../GetImage'
import { generarUuid } from '../GenerarUuid'

import { getAxiosService } from '../../services/getAxiosService'

import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'

const Pruebas = () => {
  const [state, setState] = useState({
    packs: [],
  })

  const getProducts = async () => {
    try {
      const url = BACK_END_ROUTES.homeProduct
      const response = await getAxiosService(url)
      const { responseContent } = response
      return responseContent
    } catch (error) {}
  }

  const fetchProducts = async () => {
    try {
      const productsData = await getProducts()

      if (Object.keys(productsData).length > 0) {
        const arrProducts = productsData.products

        const packs = arrProducts
          .filter(product => product.productType === 'BUNDLE')
          .toSorted((a, b) => b.amount - a.amount)

        setState({
          packs,
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  const { packs } = state

  return (
    <div className="claro__layout">
      <div className="claro__layout__container" style={{ padding: 0 }}>
        <div className="claro__recommended__package__content__min__pruebas">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 2,
              stretch: -10,
              depth: 180,
              modifier: 4,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Navigation]}
          >
            {packs.map(pack => (
              <SwiperSlide key={`${pack.productId}-${generarUuid()}`}>
                <div className="claro__recommended__package__min">
                  <div className="package__box__title package__box__title__bundle">
                    <p>
                      {pack.title}
                      <br />
                      {` ${pack.subtitle} - ${pack.validity}`}
                    </p>
                  </div>

                  <div className="package__box__content">
                    <div className="socialMedia">
                      {pack.socialMedia[0]?.icons.map(item => (
                        <img
                          key={item}
                          src={getImage(item)}
                          alt="social-icon"
                        />
                      ))}
                    </div>

                    <div className="amount__bundle">
                      {pack.currency}
                      {pack.amount}
                    </div>

                    <div className="description">{pack.mainDescription}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Pruebas
