const CategoriesOptions = ({ currentCategory, handleCategory, bundle }) => {
  return (
    <div className="claro__carousel__categories">
      {Object.keys(bundle).length > 0 && (
        <div
          className={`claro__category__option ${currentCategory === 'bundle' &&
            'active__cat__bundle'}`}
          onClick={
            currentCategory !== 'bundle' ? () => handleCategory('bundle') : null
          }
        >
          <label>Superpacks</label>
        </div>
      )}
      <div className="claro__category__option" />
      <div
        className={`claro__category__option ${currentCategory === 'topup' &&
          'active__cat__topup'}`}
        onClick={
          currentCategory !== 'topup' ? () => handleCategory('topup') : null
        }
      >
        <label>Recargas</label>
      </div>
    </div>
  )
}

export default CategoriesOptions
