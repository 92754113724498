import React, { useContext, useState, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { getLocalStorage } from '../../services/storage'

import { RESPONSE_CODE } from '../../constants/responseCode'
import { labels, CODE_DELETEACCOUNTBI } from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'

import handleRedirectHome from '../../components/HandleRedirectHome'
import PaymentActionsNext from '../../components/PaymentActionsNext'
import CustomButton from '../../components/CustomButton'

import warningPhone from '../../assets/img/warningAdm.svg'
import warning from '../../assets/img/timesAllowed.svg'
import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'
import logoBi from '../../assets/img/Bi-Logotipo-Azul.png'
import successfulPayment from '../../assets/img/successfulPayment.svg'
import processingDeleteAccountBi from '../../assets/img/processingPayment.svg'

const ClaroDeleteAccountBi = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    OK_CLARO_PAY,
    VALIDATION_CODE_EXPIRED,
    ERROR_GENERAL_DELETE_ACCOUNT_BI,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const {
    deleteAccountBiData,
    setIsVisibleAddNewPaymentMethod,
    setIsVisibleSectionAddNewCard,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    responseService,
    setResponseService,
    setProcessingPayment,
    processingPayment,
    deleteAccountBi,
    getImage,
    sendMetrics,
  } = claroPayContext

  const { HOMEPAGE, PROCESSING_SESSION } = labels

  const [alias, setAlias] = useState([])
  const [confirmacion, setConfirmacion] = useState(false)

  useEffect(() => {
    setAlias(deleteAccountBiData.alias)
  }, [deleteAccountBiData])

  const handleSelectAccountBi = accountBi => {
    const metricsData = {
      metricType: 'PAC',
      metricName: 'SelecionCuenta',
    }
    sendMetrics(metricsData)
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewAccountBi(false)
    if (selectAccountBi && selectAccountBi === accountBi) {
      setSelectAccountBi(null)
    } else {
      setSelectAccountBi(accountBi)
    }
  }

  const handleContinuar = () => {
    setConfirmacion(!confirmacion)
  }

  const handleRedirect = () => {
    const localCode = getLocalStorage(CODE_DELETEACCOUNTBI)
    const url = `${localRoutes.routesClaroPay.claroDeleteAccountBi}?code=${localCode}`
    window.location.href = window.location.origin + url
  }

  const handleService = async () => {
    const metricsData = {
      metricType: 'PAC',
      metricName: 'ConfirmacionBajaCuenta',
    }
    sendMetrics(metricsData)
    setProcessingPayment(true)
    const response = await deleteAccountBi()
    setResponseService(response)
    if (response.code === OK_CLARO_PAY.code) {
      const metricsData = {
        metricType: 'PAC',
        metricName: 'EliminacionCuentaOK',
      }
      sendMetrics(metricsData)

      setTimeout(() => {
        handleRedirectHome()
      }, 6000)
    }
  }

  return (
    <div className="claro__payment__process">
      {processingPayment ? (
        <>
          {responseService === null ? (
            <div className="claro__payment__process__waiting">
              <img
                className="claro__payment__process__image"
                src={processingDeleteAccountBi}
                alt="waiting"
              />
              <p className="claro__payment__process__generalMessage">
                {PROCESSING_SESSION}
              </p>
            </div>
          ) : (
            <>
              {responseService.code === OK_CLARO_PAY.code && (
                <>
                  <div className="claro__payment__process__sucess">
                    <div className="claro__payment__process__sucess__information__general">
                      <img
                        className="claro__payment__process__image"
                        src={successfulPayment}
                        alt="sucess"
                      />
                      <p className="claro__payment__process__generalMessage">
                        Tu cuenta ha sido dada de baja exitosamente...
                      </p>
                      <p className="claro__payment__process__generalMessage">
                        Puedes volver seguir comprando tus Paquetes / Recargas
                        Afiliando Nuevamente una cuenta bancaria o agregando
                        otro método de pago.
                      </p>
                    </div>
                  </div>

                  <PaymentActionsNext
                    textBtn={HOMEPAGE}
                    onClick={handleRedirectHome}
                    enabled={true}
                  />
                </>
              )}

              {(responseService.code === VALIDATION_CODE_EXPIRED.code ||
                responseService.code === ERROR_GENERAL_DELETE_ACCOUNT_BI.code ||
                responseService.code === FAIL_SERVER_CLARO_PAY.code) && (
                <>
                  <div className="claro__payment__process__error">
                    {responseService.img !== null &&
                      responseService.img !== undefined && (
                        <img
                          className="claro__payment__process__image"
                          src={getImage(responseService.img)}
                          alt="error"
                        />
                      )}
                    <p className="claro__payment__process__generalMessage">
                      {responseService.description}
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={responseService.title_button}
                    onClick={handleRedirect}
                    enabled={true}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {confirmacion === false ? (
            <>
              <div className="claro__payment__process__waiting">
                <img
                  className="claro__payment__process__image"
                  src={warningPhone}
                  alt="warningPhone"
                />
                <p className="claro__payment__process__generalMessage">
                  Selecciona la cuenta que deseas eliminar:
                </p>

                {alias.map(item => {
                  const isSelected = selectAccountBi === item.alias
                  return (
                    <div
                      className="account__information_container"
                      key={item.alias}
                    >
                      <div
                        className="account__information"
                        onClick={() => handleSelectAccountBi(item.alias)}
                      >
                        <div>
                          <img
                            src={
                              isSelected ? circleAccepted : circleNotAccepted
                            }
                            alt={item.alias}
                          />
                        </div>

                        <div>
                          <p>
                            {item.alias.length > 12
                              ? item.alias.slice(0, 12) + '...'
                              : item.alias}
                          </p>
                        </div>

                        <div>
                          <img
                            src={logoBi}
                            alt="logoBi"
                            style={{ height: '32px', width: '32px' }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <PaymentActionsNext
                textBtn={'Continuar'}
                onClick={handleContinuar}
                enabled={selectAccountBi}
              />
            </>
          ) : (
            <>
              <div className="claro__payment__process__waiting">
                <img
                  className="claro__payment__process__image"
                  src={warning}
                  alt="warning"
                />
                <p className="claro__payment__process__generalMessage">
                  Al eliminar esta cuenta, se borraran todos los datos de
                  afiliación....
                </p>
                <p className="claro__payment__process__generalMessage">
                  Si deseas volver a afiliarlo, debes iniciar el proceso
                  nuevamente.
                </p>
              </div>
              <div className="container__btnConfirmar">
                <CustomButton
                  text="Regresar"
                  onClick={handleContinuar}
                  customClass={'btn__regresar'}
                  enabled={true}
                />

                <CustomButton
                  text="Eliminar"
                  onClick={handleService}
                  customClass={'btn__enviar'}
                  enabled={true}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ClaroDeleteAccountBi
