import React, { useEffect } from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './App.scss'

import PrivateRoute from './components/PrivateRoute'

import ClaroPayLayout from './containers/ClaroPayLayout'
import ClaroMinecoLayout from './containers/ClaroMinecoLayout'
import ClaroHomeLayout from './containers/ClaroHomeLayout'

import Country from './constants/Country'
import localRoutes from './constants/routes'

import ClaroPrivacity from './views/ClaroTyC/Privacity'
import ClaroTyC from './views/ClaroTyC/TyC_Handler'
import ClaroTyCADM from './views/ClaroTyC/TyC_Handler_ADM'
import ClaroHomeAuth from './views/ClaroHomeAuth'
import ClaroHomeHe from './views/ClaroHomeHe'
import ClaroHome from './views/ClaroHome'
import ClaroHomePayment from './views/ClaroHomePayment'
import ClaroRecurrence from './views/ClaroRecurrence'
import ClaroPayPaymentProcess from './views/ClaroPayPaymentProcess'
import ClaroBillingProcess from './views/ClaroBillingProcess'
import ClaroAdmProcess from './views/ClaroAdmProcess'
import ClaroSessionBi from './views/ClaroSessionBi'
import ClaroWhoCalledMe from './views/ClaroWhoCalledMe'
import ClaroBuyNowPayLater from './views/ClaroBuyNowPayLater'
import ClaroWalletRecovery from './views/ClaroWalletRecovery'
import ClaroBecasMineco from './views/ClaroBecasMineco'
import ClaroCallBackUssd from './views/ClaroCallBackUssd'
import ClaroBilling from './views/ClaroBilling'
import ClaroAdm from './views/ClaroAdm'
import ClaroDeleteAccountBi from './views/ClaroDeleteAccountBi'
import ClaroDemoBi from './views/ClaroDemoBi'
import ClaroFailureBi from './views/ClaroFailureBi'
import ClaroSendARecharge from './views/ClaroSendARecharge'

import ClaroPruebas from './components/Pruebas'

const App = () => {
  const { domain } = Country()

  useEffect(() => {
    document.title = domain
    if (
      window.location.protocol === 'http:' &&
      window.location.protocol !== 'https:' &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.href =
        'https://' +
        window.location.host +
        window.location.pathname +
        window.location.search
    }
  }, [domain])

  const {
    routesClaroPay: {
      claroTyC,
      claroTyCADM,
      claroPrivacity,
      claroPayPaymentProcess,
      claroBillingProcess,
      claroAdmProcess,
      claroRecurrence,
      claroWhoCalledMe,
      claroBuyNowPayLater,
      claroWalletRecovery,
      claroBecasMineco,
      claroCallBackUssd,
      claroBilling,
      claroAdm,
      claroSessionBi,
      claroDeleteAccountBi,
      claroDemoBi,
      claroFailureBi,
      claroSendARecharge,
    },
  } = localRoutes

  const pathname = window.location.pathname
  const isClaroPay = pathname.includes('landing')
  const isBecasMineco = pathname === '/becas'
  const isDemoBi = pathname === '/bi'
  const isPruebas = pathname === '/pruebas'

  const conditionsPathnameShorts = [
    pathname !== '/a',
    pathname !== '/c',
    pathname !== '/d',
    pathname !== '/e',
    pathname !== '/f',
    pathname !== '/p',
    pathname !== '/r',
    pathname !== '/u',
    pathname !== '/w',
  ]

  const routeMapping = {
    a: claroAdm,
    c: claroAdm,
    d: claroWalletRecovery,
    e: claroDeleteAccountBi,
    f: claroBilling,
    p: claroBuyNowPayLater,
    r: claroRecurrence,
    u: claroCallBackUssd,
    w: claroWhoCalledMe,
  }

  let selectedPathname = null

  conditionsPathnameShorts.forEach(condition => {
    if (condition) {
      selectedPathname = pathname.split('/')[1]
    }
  })

  const isPathnameShorts = conditionsPathnameShorts.some(
    condition => !condition,
  )

  return (
    <BrowserRouter>
      {!isClaroPay &&
        !isBecasMineco &&
        !isDemoBi &&
        !isPathnameShorts &&
        !isPruebas && (
          <ClaroHomeLayout>
            <Switch>
              <Route path={claroTyC} exact component={ClaroTyC} />
              <Route path={claroTyCADM} exact component={ClaroTyCADM} />
              <Route path={claroPrivacity} exact component={ClaroPrivacity} />
              <Route
                path={claroPayPaymentProcess}
                exact
                component={ClaroPayPaymentProcess}
              />
              <Route
                path={claroBillingProcess}
                exact
                component={ClaroBillingProcess}
              />
              <Route path="/auth" exact component={ClaroHomeAuth} />
              <Route path="/homehe" exact component={ClaroHomeHe} />
              <Route path="/home" exact component={ClaroHome} />
              <PrivateRoute
                path="/home-payment"
                exact
                component={ClaroHomePayment}
              />
              <PrivateRoute path="*" component={ClaroHome} />
            </Switch>
          </ClaroHomeLayout>
        )}

      {(isClaroPay || isDemoBi) && (
        <ClaroPayLayout>
          <Switch>
            <Route path={claroTyC} exact component={ClaroTyC} />
            <Route path={claroTyCADM} exact component={ClaroTyCADM} />
            <Route path={claroPrivacity} exact component={ClaroPrivacity} />
            <Route
              path={claroPayPaymentProcess}
              exact
              component={ClaroPayPaymentProcess}
            />
            <Route
              path={claroBillingProcess}
              exact
              component={ClaroBillingProcess}
            />
            <Route path={claroAdmProcess} exact component={ClaroAdmProcess} />
            <Route path={claroRecurrence} exact component={ClaroRecurrence} />
            <Route path={claroWhoCalledMe} exact component={ClaroWhoCalledMe} />
            <Route
              path={claroBuyNowPayLater}
              exact
              component={ClaroBuyNowPayLater}
            />
            <Route
              path={claroWalletRecovery}
              exact
              component={ClaroWalletRecovery}
            />
            <Route
              path={claroCallBackUssd}
              exact
              component={ClaroCallBackUssd}
            />
            <Route path={claroBilling} exact component={ClaroBilling} />
            <Route path={claroAdm} exact component={ClaroAdm} />
            <Route path={claroSessionBi} exact component={ClaroSessionBi} />
            <Route
              path={claroDeleteAccountBi}
              exact
              component={ClaroDeleteAccountBi}
            />
            <Route path={claroFailureBi} exact component={ClaroFailureBi} />
            <Route
              path={claroSendARecharge}
              exact
              component={ClaroSendARecharge}
            />
            <Route path={claroDemoBi} exact component={ClaroDemoBi} />
          </Switch>
        </ClaroPayLayout>
      )}

      {isBecasMineco && (
        <ClaroMinecoLayout>
          <Switch>
            <Route path={claroTyC} exact component={ClaroTyC} />
            <Route path={claroPrivacity} exact component={ClaroPrivacity} />
            <Route path={claroBecasMineco} exact component={ClaroBecasMineco} />
            <Route
              path={claroPayPaymentProcess}
              exact
              component={ClaroPayPaymentProcess}
            />
            <Route
              path={claroBillingProcess}
              exact
              component={ClaroBillingProcess}
            />
          </Switch>
        </ClaroMinecoLayout>
      )}

      {isPathnameShorts && (
        <Switch>
          <Route
            path={`/${selectedPathname}/`}
            exact
            render={props => {
              const parametrosURL = props.location.search.substring(1)
              const selectedRoute = routeMapping[selectedPathname]
              const urlDestino = `${selectedRoute}?code=${parametrosURL}`
              window.location.href = urlDestino
              return null
            }}
          />
        </Switch>
      )}

      {isPruebas && (
        <>
          <Switch>
            <Route path="/pruebas" exact component={ClaroPruebas} />
          </Switch>
        </>
      )}
    </BrowserRouter>
  )
}

export default App
