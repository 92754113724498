import { useState } from 'react'

export const useTouch = handleNextProduct => {
  const [touchStartPosition, setTouchStartPosition] = useState(null)
  const [touchCurrentPosition, setTouchCurrentPosition] = useState(null)

  const handleTouchStart = event => {
    setTouchStartPosition(event.touches[0].clientX)
  }

  const handleTouchMove = event => {
    setTouchCurrentPosition(event.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStartPosition !== null && touchCurrentPosition !== null) {
      const touchDistance = touchCurrentPosition - touchStartPosition
      if (touchDistance > 0) {
        handleNextProduct('prev')
      } else if (touchDistance < 0) {
        handleNextProduct('next')
      }
    }
    setTouchStartPosition(null)
    setTouchCurrentPosition(null)
  }

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}

export default useTouch
