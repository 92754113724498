import React, { useContext, useEffect, useState } from 'react'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'
import { postAxiosService } from '../../services/postAxiosService'

import ClaroPayContext from '../../context/ClaroPayContext'

import localRoutes from '../../constants/routes'
import { RESPONSE_CODE } from '../../constants/responseCode'
import { labels, METRICS_SESSION } from '../../constants/ClaroPayConstants'
import elementNameCodeMap from '../../constants/Metrics'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'

import handleRedirectHome from '../../components/HandleRedirectHome'
import PaymentActionsNext from '../../components/PaymentActionsNext'

import sad from '../../assets/img/sad.svg'
import processingSession from '../../assets/img/processingPayment.svg'
import successfulPayment from '../../assets/img/successfulPayment.svg'
import unknowError from '../../assets/img/unknowError.svg'
import phoneSMS from '../../assets/img/phoneSMS.svg'
import unavailableScheduleImg from '../../assets/img/unavailableSchedule.svg'
import withoutProvisioningImg from '../../assets/img/withoutProvisioning.svg'
import connectionFailureImg from '../../assets/img/connectionFailure.svg'

const ClaroSessionBi = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    sessionBiData,
    responseService,
    setResponseService,
    getImage,
  } = claroPayContext

  const {
    OK_CLARO_PAY,
    SESSION_CODE_EXPIRED,
    VALIDATION_CODE_EXPIRED,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const {
    PROCESSING_SESSION,
    PURCHASE_MADE,
    REMEMBER_WILL_SEND_SMS,
    SELECTED_ACTIVE_RECHARGE,
    HOMEPAGE,
    REGRESAR,
  } = labels

  const [resultOk, setResultOk] = useState(null)
  const [urlRedirect, setUrlRedirect] = useState('')

  const successMessage_0 = 0
  const insufficientFundsMessage_1 = 1
  const otherPaymentFailureMessage_2 = 2
  const paymentInProgressMessage_3 = 3
  const enrollmentFailureMessage_4 = 4
  const enrollmentPendingMessage_5 = 5
  const bankNotFinishedMessage_6 = 6
  const notNotifiedMessage_7 = 7
  const unavailableSchedule_8 = 8
  const generalError_9 = 9
  const withoutProvisioning_10 = 10
  const connectionFailure_11 = 11
  const previouslyAffiliated_12 = 12
  const errorLimitBi_13 = 13

  useEffect(() => {
    fetchData()
  }, [sessionBiData])

  const handleRedirectHomeSend = value => {
    if (value) {
      handleRedirectHome()
    } else {
      setTimeout(() => {
        handleRedirectHome()
      }, 8000)
    }
  }

  const handleRedirect = (value, url) => {
    if (value) {
      window.location.href = window.location.origin + urlRedirect
    } else {
      setTimeout(() => {
        window.location.href = window.location.origin + url
      }, 6000)
    }
  }

  const processMetrics = async result => {
    if (result === successMessage_0) {
      const metricsData_1 = {
        metricName: 'CuentaAfiliadaOK_PagoOK',
      }
      const metricsData_2 = {
        metricType: 'category',
        metricName: 'CuentaAfiliada',
      }
      await sendMetrics(metricsData_1)
      await sendMetrics(metricsData_2)
    } else if (result == insufficientFundsMessage_1) {
      const metricsData_1 = {
        metricName: 'Error_CuentaAfiliadaOK_SinFondos',
      }
      const metricsData_2 = {
        metricType: 'category',
        metricName: 'CuentaAfiliada',
      }
      await sendMetrics(metricsData_1)
      await sendMetrics(metricsData_2)
    } else if (
      result === otherPaymentFailureMessage_2 ||
      result === withoutProvisioning_10 ||
      result === errorLimitBi_13
    ) {
      const metricsData_1 = {
        metricName: 'Error_CuentaAfiliadaOK_ErrorBanco',
      }
      const metricsData_2 = {
        metricType: 'category',
        metricName: 'CuentaAfiliada',
      }
      await sendMetrics(metricsData_1)
      await sendMetrics(metricsData_2)
    } else if (result === paymentInProgressMessage_3) {
      const metricsData_1 = {
        metricName: 'Error_CuentaAfiliadaOK_ProcesoBanco',
      }
      const metricsData_2 = {
        metricType: 'category',
        metricName: 'CuentaAfiliada',
      }
      await sendMetrics(metricsData_1)
      await sendMetrics(metricsData_2)
    } else if (
      result === enrollmentFailureMessage_4 ||
      result === enrollmentPendingMessage_5 ||
      result === bankNotFinishedMessage_6 ||
      result === notNotifiedMessage_7 ||
      result === previouslyAffiliated_12
    ) {
      const metricsData = {
        metricType: 'category',
        metricName: 'Error_Afiliación',
      }
      await sendMetrics(metricsData)
    } else if (
      result === unavailableSchedule_8 ||
      result === generalError_9 ||
      result === connectionFailure_11
    ) {
      const metricsData = {
        metricName: 'Error_ErrorBanco',
      }
      await sendMetrics(metricsData)
    }
  }

  const sendMetrics = async metricsData => {
    const { metricType, metricName } = metricsData

    const storedData = getLocalStorage(METRICS_SESSION)

    if (storedData) {
      const parsedData = JSON.parse(storedData)

      const elementLocationMetrics = parsedData.elementLocation
      const elementLocationCodeMetrics = parsedData.elementLocationCode
      const elementName = parsedData.elementName
      const msisdnMetrics = parsedData.msisdn
      const code = parsedData.code

      let elementNameMetrics = ''
      let category = null

      if (metricType === 'category') {
        const start = elementName.indexOf('CheckOut_') + 'CheckOut_'.length
        const end = elementName.indexOf('-BotonPagar')
        category = elementName.substring(start, end)
      }

      if (elementLocationMetrics === 'Who Call Me') {
        const suffix = elementLocationCodeMetrics === 'WCB' ? '_B' : '_A'
        elementNameMetrics =
          metricName + (category ? `-${category}` : '') + suffix
      } else {
        elementNameMetrics = metricName + (category ? `-${category}` : '')
      }

      const elementNameCodeMetrics =
        elementNameCodeMap[elementNameMetrics] || 'CódigoNoEncontrado'
      try {
        const data = {
          elementLocation: elementLocationMetrics,
          elementLocationCode: elementLocationCodeMetrics,
          elementName: elementNameMetrics,
          elementNameCode: elementNameCodeMetrics,
          msisdn: msisdnMetrics,
          code: code ?? '-',
        }
        const url = BACK_END_ROUTES.registerMetrics
        await postAxiosService(url, data)
        clearLocalStorage(METRICS_SESSION)
      } catch (error) {
        clearLocalStorage(METRICS_SESSION)
      }
    }
  }

  const responseOk = async response => {
    const { responseContent } = response
    if (responseContent) {
      const { code, landing, result } = responseContent
      setResultOk(result)
      const landingRoute = localRoutes.routesClaroPay[landing]
      const url = `${landingRoute}?code=${code}`
      setUrlRedirect(url)
      await processMetrics(result)
      handleRedirect(false, url)
    } else {
      handleRedirectHomeSend(true)
    }
  }

  const processSessionResponse = async response => {
    switch (response.responseCode) {
      case OK_CLARO_PAY.code:
        responseOk(response)
        return OK_CLARO_PAY

      case SESSION_CODE_EXPIRED.code:
        handleRedirectHomeSend(false)
        return SESSION_CODE_EXPIRED

      case VALIDATION_CODE_EXPIRED.code:
        handleRedirectHomeSend(false)
        return VALIDATION_CODE_EXPIRED

      case FAIL_SERVER_CLARO_PAY.code:
        handleRedirectHomeSend(false)
        return FAIL_SERVER_CLARO_PAY

      default:
        return response
    }
  }

  const fetchData = async () => {
    if (sessionBiData) {
      let response = await processSessionResponse(sessionBiData)
      setResponseService(response)
    } else {
      handleRedirectHomeSend(false)
    }
  }

  return (
    <div className="claro__payment__process">
      {responseService !== null ? (
        <>
          {responseService.code === OK_CLARO_PAY.code && (
            <div className="claro__payment__process__sucess">
              {resultOk == successMessage_0 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Tu cuenta se ha Afiliado Correctamente.
                    </p>
                    <br />
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      <span role="img" aria-label="Snowman">
                        {PURCHASE_MADE}.
                      </span>
                    </p>
                  </div>
                  <div className="claro__payment__process__sucess__noteSms">
                    <img src={phoneSMS} alt="phone" />{' '}
                    <p>
                      {REMEMBER_WILL_SEND_SMS}
                      <span> {SELECTED_ACTIVE_RECHARGE}.</span>
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={HOMEPAGE}
                    onClick={() => handleRedirectHomeSend(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == insufficientFundsMessage_1 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Tu cuenta se ha Afiliado Correctamente.
                    </p>
                    <br />
                    <img src={unknowError} alt="error" />
                    <p className="claro__payment__process__generalMessage">
                      Lamentablemente, no tienes fondos suficientes para
                      completar esta solicitud.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == otherPaymentFailureMessage_2 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Tu cuenta se ha Afiliado Correctamente.
                    </p>
                    <br />
                    <img src={unknowError} alt="error" />
                    <p className="claro__payment__process__generalMessage">
                      En este momento, se presenta un Error, No podemos realizar
                      el débito a tu cuenta.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == paymentInProgressMessage_3 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Tu cuenta se ha Afiliado Correctamente.
                    </p>
                    <br />
                    <img src={processingSession} alt="waiting" />
                    <p className="claro__payment__process__generalMessage">
                      Estamos procesando el pago con tu banco....
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {(resultOk == enrollmentFailureMessage_4 ||
                resultOk == enrollmentPendingMessage_5 ||
                resultOk == bankNotFinishedMessage_6 ||
                resultOk == notNotifiedMessage_7) && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img src={unknowError} alt="error" />
                    <p className="claro__payment__process__generalMessage">
                      En este momento no hemos podido completar el proceso de
                      Afiliación, por favor, intenta más tarde.
                    </p>
                    <br />
                    <br />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == unavailableSchedule_8 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      src={unavailableScheduleImg}
                      alt="unavailableSchedule"
                    />
                    <br />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Por el momento en este horario no se puede realizar el
                      pago con tu cuenta Bancaria.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == generalError_9 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img src={unknowError} alt="error" />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      En este momento no fue posible el pago con tu Banco.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == withoutProvisioning_10 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      src={withoutProvisioningImg}
                      alt="withoutProvisioningImg"
                    />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      No Logramos Procesar el
                      <br />
                      Paquete/Recarga que compraste.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      En un momento procedemos a <br />
                      devolverte el dinero de la compra.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == connectionFailure_11 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      src={connectionFailureImg}
                      alt="connectionFailureImg"
                    />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Tenemos un Error de Conexión con el Banco. Por favor,
                      intenta más tarde.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == previouslyAffiliated_12 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img src={unknowError} alt="error" />
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      La cuenta que intentas afiliar, ya se encuentra
                      Registrada. <br />
                      Presiona el Botón "Regresar" para iniciar de nuevo.
                    </p>
                  </div>

                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}

              {resultOk == errorLimitBi_13 && (
                <>
                  <div className="claro__payment__process__sucess__information__general">
                    <img
                      className="claro__payment__process__image"
                      src={successfulPayment}
                      alt="sucess"
                    />
                    <p className="claro__payment__process__generalMessage">
                      Tu cuenta se ha Afiliado Correctamente.
                    </p>
                    <br />
                    <img src={unknowError} alt="error" />
                    <p className="claro__payment__process__generalMessage">
                      En este momento no fue posible el pago con tu Banco.
                    </p>
                    <br />
                    <p className="claro__payment__process__generalMessage">
                      Presiona el Botón “Regresar” para otro método de pago.
                    </p>
                  </div>
                  <PaymentActionsNext
                    textBtn={REGRESAR}
                    onClick={() => handleRedirect(true)}
                    enabled={true}
                  />
                </>
              )}
            </div>
          )}

          {(responseService.code === SESSION_CODE_EXPIRED.code ||
            responseService.code === VALIDATION_CODE_EXPIRED.code ||
            responseService.code === FAIL_SERVER_CLARO_PAY.code) && (
            <div className="claro__payment__process__error">
              {responseService.img !== null &&
                responseService.img !== undefined && (
                  <img
                    className="claro__payment__process__image"
                    src={getImage(responseService.img)}
                    alt="error"
                  />
                )}

              {responseService.code === SESSION_CODE_EXPIRED.code && (
                <p className="claro__payment__process__generalMessage">
                  {responseService.description}
                </p>
              )}

              {(responseService.code === VALIDATION_CODE_EXPIRED.code ||
                responseService.code === FAIL_SERVER_CLARO_PAY.code) && (
                <p className="claro__payment__process__generalMessage">
                  {responseService.description
                    .split('.')
                    .map((sentence, index, array) => (
                      <React.Fragment key={sentence.trim()}>
                        {sentence.trim()}
                        {index === array.length - 1 ? (
                          <img
                            src={sad}
                            alt="Sad"
                            className="claro__payment__process__error__imageEmoji"
                          />
                        ) : (
                          '.'
                        )}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="claro__payment__process__waiting">
          <img
            className="claro__payment__process__image"
            src={processingSession}
            alt="waiting"
          />
          <p className="claro__payment__process__generalMessage">
            {PROCESSING_SESSION}
          </p>
        </div>
      )}
    </div>
  )
}

export default ClaroSessionBi
