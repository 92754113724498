import React from 'react'

const CustomButton = ({ onClick, text, customClass, enabled, img }) => {
  return (
    <button
      disabled={!enabled}
      type="button"
      className={`${customClass}${!enabled ? ' disabled' : ''}`}
      onClick={onClick}
    >
      {img && <img src={img} alt="claro-icon" />}
      <p>{text}</p>
    </button>
  )
}

export default CustomButton
